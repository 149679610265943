import { SchedulingDateFunctions } from '../../services/scheduling-date-fns';
import { AvailabilitySettingByDay, AvailabilitySettingTimeslot } from '../../services/graphql/generated';

export function calculateWeeklyAvailableHours(settingsByDay: AvailabilitySettingByDay[] | null | undefined): number {
    if (!settingsByDay) {
        return 0;
    }
    let sumOfWeeklyAvailableHours = 0;

    settingsByDay.forEach((setting: AvailabilitySettingByDay) => {
        setting.timeslots.forEach((timeSlot: AvailabilitySettingTimeslot) => {
            const duration = SchedulingDateFunctions.getIntervalFromTimesMilitaryFormat(
                timeSlot.startTime,
                timeSlot.endTime,
            );
            const durationInHours = duration.length('hours');
            sumOfWeeklyAvailableHours += durationInHours;
        });
    });

    return sumOfWeeklyAvailableHours;
}

export function showWeeklyClinicalHours(userWeeklyClinicalHours?: number | null): string | null {
    const weeklyClinicalHours = userWeeklyClinicalHours != null ? `${userWeeklyClinicalHours} hours` : null;
    return weeklyClinicalHours;
}
