import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import './other-settings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'concert-ui-library';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { TreeNode } from 'primereact/treenode';
import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import {
    getUserConcertSettings,
    resetAcceptingNewPatientsSaveStatus,
    resetSaveEngagementTypesStatus,
    resetUserPracticesSaveState,
    saveAcceptingNewPatients,
    saveEngagementTypes,
    saveUserPractices,
    saveUserPracticesFailure,
    UserPracticeNode,
} from './slice';
import {
    selectConcertSettings,
    selectUserConcertSettings,
    selectIsConcertSettingsLoading,
    selectIsAcceptingNewPatientsSaving,
    selectIsAcceptingNewPatientsSucceeded,
    selectIsEngagementTypesSaving,
    selectIsEngagementTypesSucceeded,
    selectUserPractices,
    selectUserPracticeSelections,
    selectIsUserPracticesSucceeded,
    selectIsUserPracticesUpdating,
    selectIsUserPracticesUpdateSucceeded,
} from './selector';
import { selectUser } from '../../user/selector';
import { selectSettingsUser } from '../selector';
import { isManagerOrSuperUser } from '../availability-functions';
import { CheckboxTree, SelectionChangeEvent } from '../../../components/checkbox-tree';
import { flattenToSelections, toTreeFormat } from './other-settings-user-practice-transformations';
import { getEngagementTypes } from '../../engagement-types/slice';
import { selectEngagementTypes, selectIsEnagementTypesLoading } from '../../engagement-types/selector';
import { NotificationContext } from '../../../notification-context';
import { getPractices, Practice } from '../../practices/slice';
import { PracticeMultiSelector } from '../../practices/pratices-multi-selector';
import { selectPractices } from '../../practices/selector';

export const OtherSettings: FunctionComponent = () => {
    const dispatch = useDispatch();
    const settings = useSelector(selectConcertSettings);
    const userConcertSettings = useSelector(selectUserConcertSettings);
    const settingsIsLoading = useSelector(selectIsConcertSettingsLoading);
    const userPracticeSelections = useSelector(selectUserPracticeSelections);
    const userPracticesAreUpdating = useSelector(selectIsUserPracticesUpdating);
    const settingsUser = useSelector(selectSettingsUser);
    const user = useSelector(selectUser);
    const userPractices = useSelector(selectUserPractices);
    const practices = useSelector(selectPractices);
    const [filteredPractices, setFilteredPractices] = useState<Practice[]>([]);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<TreeCheckboxSelectionKeys>();
    const [accountAvailabilityData, setAccountAvailabilityData] = useState<TreeNode[]>();
    const isAcceptingNewPatientsSaving = useSelector(selectIsAcceptingNewPatientsSaving);
    const isAcceptingNewPatientsSucceeded = useSelector(selectIsAcceptingNewPatientsSucceeded);

    const engagementTypes = useSelector(selectEngagementTypes);
    const isEngagementTypesLoading = useSelector(selectIsEnagementTypesLoading);
    const [settingsToBeModified, setSettingsToBeModified] = useState(userConcertSettings);
    const [isEditingDisabled, setIsEditingDisabled] = useState(!isManagerOrSuperUser(user, settingsUser));
    const isEngagementTypesSaving = useSelector(selectIsEngagementTypesSaving);
    const isEngagementTypesSucceeded = useSelector(selectIsEngagementTypesSucceeded);
    const isUserPracticesSaveSucceeded = useSelector(selectIsUserPracticesSucceeded);
    const isUserPracticeUpdateSucceeded = useSelector(selectIsUserPracticesUpdateSucceeded);
    const [selectedPractices, setSelectedPractices] = useState<Practice[]>([]);
    const onSelectedPractice = (value: Practice[]) => {
        setSelectedPractices([...value]);
    };

    const notificationContext = useContext(NotificationContext);

    const setAcceptingNewPatient = (checked: boolean) => {
        if (isEditingDisabled) {
            return;
        }
        setSettingsToBeModified({ ...userConcertSettings, acceptingNewPatients: checked });
        dispatch(saveAcceptingNewPatients({ userId: settingsUser.id, acceptingNewPatients: checked }));
    };

    const onChangeEngagementTypes = (value: string[]) => {
        if (value == null || value.length === 0) {
            notificationContext.showError('At least one engagement type is required');
            return;
        }
        setSettingsToBeModified({ ...userConcertSettings, engagementTypes: value });
        dispatch(saveEngagementTypes({ userId: settingsUser.id, engagementTypes: value }));
    };

    const onAddButtonClick = () => {
        dispatch(
            saveUserPractices({
                userPractices: selectedPractices.map((p: Practice) => {
                    return { accountId: p.id, acceptingNewPatients: true, userId: settingsUser.id };
                }),
                type: 'insert',
                selections: [selectedPractices],
            }),
        );
        setSelectedPractices([]);
    };

    const updateUserPracticeAvailability = (payload: SelectionChangeEvent) => {
        if (isEditingDisabled) {
            dispatch(
                saveUserPracticesFailure({
                    error: {
                        customMessage: 'Setting can only be changed by a manager or super user.',
                    },
                }),
            );
        } else {
            dispatch(
                saveUserPractices({
                    type: 'update',
                    userPractices: payload.changedRecords,
                    selections: payload.newSelectedKeys,
                }),
            );
        }
    };

    const filterPractices = () => {
        const selectedKeys = userPractices?.map((p: UserPracticeNode) => p.accountId);
        setFilteredPractices(
            practices
                .filter((p: Practice) => !selectedKeys?.includes(p.id))
                .sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                }),
        );
    };

    useEffect(() => {
        setIsEditingDisabled(!isManagerOrSuperUser(user, settingsUser));
        dispatch(getUserConcertSettings(settingsUser.id));
    }, [user, settingsUser]);
    useEffect(() => {
        const practicesToBeSorted = userPractices ? [...userPractices] : [];
        setAccountAvailabilityData(
            practicesToBeSorted.map(toTreeFormat).sort((a, b) => {
                return String(a.label) > String(b.label) ? 1 : -1;
            }),
        );
        setSelectedNodeKeys(userPractices?.reduce(flattenToSelections, {}));
    }, [userPractices]);
    useEffect(() => {
        setSelectedNodeKeys(userPracticeSelections);
    }, [userPracticeSelections]);

    useEffect(() => {
        setSettingsToBeModified(userConcertSettings);
    }, [userConcertSettings]);

    useEffect(() => {
        dispatch(getEngagementTypes());
    }, []);
    useEffect(() => {
        if (!practices.length) {
            dispatch(getPractices(''));
        } else {
            filterPractices();
        }
    }, [practices]);

    useEffect(() => {
        filterPractices();
    }, [userPractices]);

    useEffect(() => {
        if (isAcceptingNewPatientsSucceeded) {
            notificationContext.showSuccess('Accepting New Patient Setting Saved');
            dispatch(resetAcceptingNewPatientsSaveStatus());
        }
        if (isEngagementTypesSucceeded) {
            notificationContext.showSuccess('Engagement Types Setting Saved');
            dispatch(resetSaveEngagementTypesStatus());
        }
        if (isUserPracticesSaveSucceeded) {
            notificationContext.showSuccess('Availability By Practice Saved');
            dispatch(getUserConcertSettings(settingsUser.id));
            dispatch(resetUserPracticesSaveState());
        }
        if (isUserPracticeUpdateSucceeded) {
            notificationContext.showSuccess('Availability By Practice Saved');
            dispatch(resetUserPracticesSaveState());
        }
    }, [
        isAcceptingNewPatientsSucceeded,
        isEngagementTypesSucceeded,
        isUserPracticesSaveSucceeded,
        isUserPracticeUpdateSucceeded,
    ]);

    return (
        <div>
            {settingsIsLoading || !settings || isEngagementTypesLoading ? (
                <Loader />
            ) : (
                <div className="other-settings-container">
                    <div className="caseload-container">
                        <div className="p-field">
                            <label className="caseload-label" htmlFor="accepting-new-patient">
                                Caseload
                            </label>
                            <div className="caseload-info">
                                <span>{`${settingsToBeModified?.activeEpisodes ?? 0} Active`} </span>
                                <span>{`${settingsToBeModified?.targetCaseLoad ?? 0} Target `}</span>
                                <span>{`${settingsToBeModified?.caseloadAllocation ?? 0}% Allocation`}</span>
                            </div>
                        </div>
                        <div className="p-field botton-accepting-new-patientes">
                            <label id="accepting-new-patient-label" htmlFor="accepting-new-patient">
                                Accepting New Patients
                            </label>
                            <div>
                                <ToggleButton
                                    aria-labelledby="accepting-new-patient-label"
                                    checked={settingsToBeModified?.acceptingNewPatients}
                                    onChange={(e) => setAcceptingNewPatient(e.value)}
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    id="accepting-new-patient"
                                    className={isEditingDisabled ? 'disabled' : 'not-disabled'}
                                />
                                {isAcceptingNewPatientsSaving ? <Loader inline /> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="p-field">
                        <label id="engagementTypesLabel" htmlFor="engagementTypes">
                            Engagement Types
                        </label>
                        <div style={{ verticalAlign: 'middle' }}>
                            <MultiSelect
                                aria-labelledby="engagementTypesLabel"
                                optionLabel="label"
                                optionValue="value"
                                display="chip"
                                value={settingsToBeModified?.engagementTypes}
                                options={engagementTypes}
                                disabled={isEditingDisabled}
                                onChange={(e) => {
                                    onChangeEngagementTypes(e.value);
                                }}
                                id="engagement-types"
                            />
                            {isEngagementTypesSaving ? <Loader inline /> : ''}
                        </div>
                    </div>
                    <div className="p-field">
                        <div className="availability-label">Availability By Practice</div>
                        <div>
                            <PracticeMultiSelector
                                onSelectedPractice={(value) => onSelectedPractice(value as Practice[])}
                                selectedPracticeParam={selectedPractices}
                                filteredPractices={filteredPractices}
                                isDisable={isEditingDisabled}
                            />
                            <Button
                                label="Add"
                                icon="pi pi-plus"
                                className="p-button p-component p-button-primary"
                                data-testid="new-row"
                                disabled={selectedPractices === null || isEditingDisabled}
                                onClick={onAddButtonClick}
                            />
                        </div>
                        <CheckboxTree
                            nodes={accountAvailabilityData}
                            selectedKeys={selectedNodeKeys}
                            loading={userPracticesAreUpdating}
                            checkboxField="acceptingNewPatients"
                            onSelectionChange={updateUserPracticeAvailability}
                        />
                        <div className="footer" />
                    </div>
                </div>
            )}
        </div>
    );
};
