import { Card } from 'primereact/card';
import { FunctionComponent } from 'react';
import './message-center.scss';
import { ConversationMessageList } from 'concert-ui-library';
import { Conversation } from '../../services/graphql/generated';

export interface ConversationViewerProps {
    conversation: Conversation | undefined | null;
}
export const ConversationViewer: FunctionComponent<ConversationViewerProps> = ({ conversation, children }) => {
    return (
        <Card>
            {children}
            <ConversationMessageList viewerType="Clinician" showPatientName conversation={conversation} />
        </Card>
    );
};
