import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { useDispatch } from 'react-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootSaga from './sagas';
import createReducer from './reducers';
import { isDevelopment } from './util';
import { api as graphQLApi } from './services/graphql/concert-base-api';

export default function configureAppStore(initialState = {}) {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const middlewares = [sagaMiddleware, routerMiddleware(history), graphQLApi.middleware];
    const store = configureStore({
        reducer: createReducer({ router: connectRouter(history) }),
        middleware: [...getDefaultMiddleware(), ...middlewares],
        preloadedState: initialState,
        devTools: isDevelopment(),
    });

    sagaMiddleware.run(rootSaga);

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    // if (module.hot) {
    //     module.hot.accept('./reducers', () => {
    //         forceReducerReload(store);
    //     });
    // }

    return store;
}

export const history = createBrowserHistory();
export const store = configureAppStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
