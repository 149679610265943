import { FC } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { SchedulerAvailabilityFilter } from './scheduler-filter/scheduler-availability-filter';
import { User } from '../user/slice';
import { AvailabilityTeamFilter, AvailabilityWhoFilterType } from './scheduler-filter/slice';
import { selectSchedulerFilter } from './scheduler-filter/selector';

type SchedulerSubHeaderProps = {
    appointmentFilterViewCollapsed: boolean;
    onFilterPanelOpenClose: (isClosed: boolean) => void;
};

export const SchedulerSubHeader: FC<SchedulerSubHeaderProps> = ({
    appointmentFilterViewCollapsed,
    onFilterPanelOpenClose,
}) => {
    const availabilityFilter = useSelector(selectSchedulerFilter);
    const getWhoFilterTeamParameterString = (whoFilter: AvailabilityTeamFilter) => {
        const whoFilters = [whoFilter.practice?.name || null, whoFilter.language || null];
        return whoFilters.filter((a) => a !== null && a !== '').join(', ');
    };

    const getAvailabilityFilterText = () => {
        const noFilterText = 'Select Episode And Concert User';
        if (!availabilityFilter.episode && !availabilityFilter.user) {
            return noFilterText;
        }
        return (
            <div className="filter-button-area">
                {availabilityFilter.episode ? (
                    <span>
                        <span className="p-text-bold"> {availabilityFilter.episode.name} </span>
                        (Episode)
                    </span>
                ) : (
                    <span>Select Episode</span>
                )}
                <span> with </span>
                {availabilityFilter.who === AvailabilityWhoFilterType.User ? (
                    <span>
                        <span className="p-text-bold">{(availabilityFilter?.user as User[])[0]?.nameWithSuffix}</span>
                        {(availabilityFilter.user as User[])?.length > 1 ? (
                            <span
                                className="other-names"
                                title={(availabilityFilter.user as User[])?.map((a) => a.nameWithSuffix).join(', ')}
                            >
                                {(availabilityFilter.user as User[]).map((u, i) => {
                                    if (i !== 0) {
                                        return `, ${u.nameWithSuffix}`;
                                    }
                                    return null;
                                })}
                            </span>
                        ) : (
                            <span>&nbsp;(Concert User)</span>
                        )}
                    </span>
                ) : (
                    <span>
                        Team (
                        <span className="p-text-bold">
                            {getWhoFilterTeamParameterString({
                                language: availabilityFilter.language,
                                practice: availabilityFilter.practice,
                            } as AvailabilityTeamFilter)}
                        </span>
                        )
                    </span>
                )}
            </div>
        );
    };
    const headerTemplate = (options: any) => {
        return (
            <div>
                <div className="scheduling-sub-header">
                    <span>Scheduling</span>
                    <span className="p-buttonset">
                        <Button
                            className="p-button-secondary p-panel-toggler filter-button"
                            icon="pi pi-pencil p-button-icon-right"
                            onClick={options.onToggle}
                        >
                            {getAvailabilityFilterText()}
                        </Button>
                    </span>
                </div>
            </div>
        );
    };
    const collapseFilter = () => {
        setAppointmentFilterViewOpenCloseState(!appointmentFilterViewCollapsed);
    };

    const setAppointmentFilterViewOpenCloseState = (isClosed: boolean) => {
        onFilterPanelOpenClose(isClosed);
    };

    return (
        <div>
            <Panel
                header={headerTemplate}
                className="app-container"
                toggleable
                collapsed={appointmentFilterViewCollapsed}
                onToggle={collapseFilter}
            >
                <div className="appContainer">
                    <SchedulerAvailabilityFilter onFilterSet={() => setAppointmentFilterViewOpenCloseState(true)} />
                </div>
            </Panel>
        </div>
    );
};
