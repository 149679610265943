import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { unAuthenticateUserInitiate } from './slice';
import { changeUser } from '../user/slice';

export const Logout: FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(unAuthenticateUserInitiate());
        dispatch(changeUser(null));
    }, []);

    const redirectToLogin = () => {
        history.push('/login');
    };
    return (
        <div>
            <Card>
                <Button className="p-button-outlined" onClick={redirectToLogin}>
                    Login with Salesforce
                </Button>
            </Card>
        </div>
    );
};
