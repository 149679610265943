import { put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosError } from 'axios';
import { getEpisodes, setEpisodesFailure, setEpisodesSuccess } from './slice';
import { ErrorConstants } from '../error/constants';

function* getEpisodesFromApi(action: any) {
    try {
        const { payload } = action;
        const { data } = yield axios.get(`${axios.defaults.baseURL}/episodes`, {
            params: {
                episodeNameFilter: payload,
            },
        });
        yield put(setEpisodesSuccess({ data }));
    } catch (error) {
        let errorMessage = '';
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        const apiError = {
            message: errorMessage,
            customMessage: `Error getting episodes. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(setEpisodesFailure({ error: apiError }));
    }
}

export default function* watchGetEpisodes(): Generator {
    yield takeEvery(getEpisodes, getEpisodesFromApi);
}
