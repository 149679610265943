import { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import './settings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import * as QueryString from 'query-string';
import { TabPanel, TabView } from 'primereact/tabview';
import { Loader } from 'concert-ui-library';
import { selectUser } from '../user/selector';
import { GeneralAvailability } from './general-availability';
import { OnsiteAvailabilty } from './onsite-availability';
import { OtherSettings } from './other/other-settings';
import { getSettingsUser, getSettingsUserSuccess } from './slice';
import { selectSettingsUser, selectSettingsUserLoading } from './selector';
import { UserSelector } from '../users/users-selector';

const SettingsComponent = () => {
    const dispatch = useDispatch();
    const currentLocation = useLocation();
    const user = useSelector(selectUser);
    const currentUser = useSelector(selectSettingsUser);
    const settingsUserLoading = useSelector(selectSettingsUserLoading);

    useEffect(() => {
        const { userId } = QueryString.parse(currentLocation.search);
        if (userId && ((!!currentUser && currentUser.id !== userId) || !currentUser)) {
            dispatch(getSettingsUser(userId));
        } else {
            dispatch(getSettingsUserSuccess(user));
        }
    }, [user]);

    const onUserSelector = (userId: string) => {
        dispatch(getSettingsUser(userId));
    };
    const [activeIndex, setActiveIndex] = useState(0);
    return settingsUserLoading === true ? (
        <Loader />
    ) : (
        <div className="settings-container">
            <div className="sub-header">
                <Panel
                    header={`Settings: ${currentUser.nameWithSuffix}`}
                    className="app-container"
                    toggleable={false}
                    collapsed
                />
                <div className="user-selector-container app-container">
                    <UserSelector
                        onSelectedUser={(userParam: any) => {
                            onUserSelector(userParam.id);
                        }}
                        selectedUserParam={currentUser}
                    />
                </div>
            </div>

            <div className="app-container">
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    style={{ marginTop: '10px' }}
                >
                    <TabPanel header="General Availability">
                        <GeneralAvailability> </GeneralAvailability>
                    </TabPanel>
                    <TabPanel header="Onsite Availability">
                        <OnsiteAvailabilty> </OnsiteAvailabilty>
                    </TabPanel>
                    <TabPanel header="Other Availability Settings">
                        <OtherSettings> </OtherSettings>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
};

const SettingsComponentWithRouter = withRouter(SettingsComponent);
export default SettingsComponentWithRouter;
