import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getPractices, setPracticesFailure, setPracticesSuccess } from './slice';
import { ErrorConstants } from '../error/constants';
import { Action, ErrorMessage } from '../../common-types';

function* getPracticesFromApi(action: Action) {
    try {
        const { payload } = action;
        const { data } = yield axios.get(`${axios.defaults.baseURL}/practices`, {
            params: {
                filter: payload,
            },
        });
        yield put(setPracticesSuccess({ data }));
    } catch (error: unknown) {
        const errorMessage = error as ErrorMessage;
        errorMessage.customMessage = null;
        const apiError = {
            message: errorMessage.message,
            customMessage: `Error getting practices. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(setPracticesFailure({ error: apiError }));
    }
}

export default function* watchGetPractices(): Generator {
    yield takeEvery(getPractices, getPracticesFromApi);
}
