import { put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosError } from 'axios';
import lscache from 'lscache';
import { ErrorConstants } from '../error/constants';
import { getEngagementTypesFailure, getEngagementTypesSuccess, getEngagementTypes } from './slice';

const EngagementTypesCachekey = 'engagement-types';

function* getEngagementTypesFromApi() {
    try {
        if (lscache.get(EngagementTypesCachekey) !== null) {
            yield put(getEngagementTypesSuccess({ data: lscache.get(EngagementTypesCachekey) }));
            return;
        }
        const { data } = yield axios.get(`${axios.defaults.baseURL}/engagement-types`);
        lscache.set(EngagementTypesCachekey, data, 120);
        yield put(getEngagementTypesSuccess({ data }));
    } catch (error) {
        let errorMessage = '';
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        const apiError = {
            message: errorMessage,
            customMessage: `Error getting engagement types. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(getEngagementTypesFailure({ error: apiError }));
    }
}

export default function* watchGetEngagementTypes(): Generator {
    yield takeEvery(getEngagementTypes, getEngagementTypesFromApi);
}
