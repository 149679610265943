import { FC, useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { selectPractices } from './selector';
import { Practice, getPractices } from './slice';
import './styles.scss';

export type PracticeSelectorProps = {
    onSelectedPractice: (practice: Practice | null) => void;
    selectedPracticeParam: Practice | null;
};

export const PracticeSelector: FC<PracticeSelectorProps> = ({ onSelectedPractice, selectedPracticeParam }) => {
    const initializePracticeParamIfEmpty = () => {
        return selectedPracticeParam === null || selectedPracticeParam === undefined ? '' : selectedPracticeParam.name;
    };

    const dispatch = useDispatch();
    const practices = useSelector(selectPractices);
    const [selectedPractice, setSelectedPractice] = useState(selectedPracticeParam);
    const [practicesText, setPracticesText] = useState(initializePracticeParamIfEmpty());
    const [filteredPratices, setFilteredPratices] = useState<Practice[]>([]);

    const searchPractices = ({ query }: AutoCompleteCompleteEvent) => {
        setFilteredPratices(
            practices.filter((p: Practice) => {
                return p.name.toLowerCase().includes(query.toLowerCase());
            }),
        );
    };

    const practiceItemTemplate = (practice: Practice) => {
        return (
            <div key={practice.id} className="practice-options">
                {practice.name}
            </div>
        );
    };

    const onChangePractice = (newValue: Practice | null) => {
        setSelectedPractice(newValue);
        onSelectedPractice(newValue);
    };

    useEffect(() => {
        if (!practices.length) {
            dispatch(getPractices(''));
        }
    }, [practices]);

    useEffect(() => {
        setPracticesText(initializePracticeParamIfEmpty());
        setSelectedPractice(selectedPracticeParam);
    }, [selectedPracticeParam]);

    return (
        <AutoComplete
            value={practicesText}
            suggestions={filteredPratices}
            placeholder="Practices..."
            delay={400}
            minLength={2}
            onChange={({ value }) => setPracticesText(value)}
            field="name"
            onSelect={({ value }) => onChangePractice(value)}
            className="practice-selector"
            itemTemplate={practiceItemTemplate}
            completeMethod={searchPractices}
            onClear={() => onSelectedPractice(null)}
            onBlur={() => {
                selectedPractice && setPracticesText(selectedPractice.name);
            }}
        />
    );
};
