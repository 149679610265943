import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SetLoginRedirectLocation = {
    pathname?: string | null;
    search?: string | null;
};
export type SetLoginRedirectLocationAction = {
    location: {
        pathname: string;
        search: string;
    };
};

export enum UserUnauthorizedReason {
    SessionExpired,
    UnauthorizedException,
}
export type SetUserUnauthorizedPayload = {
    unauthorizedReason: UserUnauthorizedReason;
};

export const initialState = {
    auth: false,
    token: '',
    unauthorizedReason: null as UserUnauthorizedReason | null,
    showSessionEndedDialog: false,
    usesSessionEndedDialogModal: false,
    loginRedirectLocation: null as SetLoginRedirectLocation | null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeAuthState(state, action) {
            state.auth = action.payload;
            state.token = action.payload.token;
        },
        unAuthenticate(state) {
            state.auth = false;
            state.token = '';
        },
        unAuthenticateUserInitiate(state) {
            state.auth = false;
            state.token = '';
        },
        userUnauthorized(state, action: PayloadAction<SetUserUnauthorizedPayload>) {
            state.unauthorizedReason = action.payload.unauthorizedReason;
        },
        usesSessionEndedDialog(state, action: PayloadAction<boolean>) {
            state.usesSessionEndedDialogModal = action.payload;
        },
        setLoginRedirectLocation(state, action: PayloadAction<SetLoginRedirectLocationAction>) {
            state.loginRedirectLocation = action.payload.location;
        },
        showSessionEndedDialog(state) {
            state.showSessionEndedDialog = true;
        },
        hideSessionEndedDialog(state) {
            state.showSessionEndedDialog = false;
        },
    },
});

export const {
    changeAuthState,
    unAuthenticate,
    unAuthenticateUserInitiate,
    userUnauthorized,
    usesSessionEndedDialog,
    setLoginRedirectLocation,
    showSessionEndedDialog,
    hideSessionEndedDialog,
} = authSlice.actions;

export const { reducer } = authSlice;
