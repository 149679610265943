export const ROUTES = {
    LOGIN: '/login',
    OAUTH_CALLBACK: '/oauth-callback',
    ERROR: '/error',
    LOGOUT: '/logout',
    APPT_SETTINGS: '/appt-settings',
    SETTINGS: '/settings',
    SCHEDULER: '/scheduler',
    MESSAGING: '/messaging',
    CONVERSATION: '/conversation',
};
export const ROUTE_PARAMS = {
    CONVERSATION_ID: 'conversationId',
    EPISODE_ID: 'episodeId',
    SALESFORCE_AUTH_CODE: 'code',
    SALESFORCE_AUTH_STATE: 'state',
};
