import { FunctionComponent, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'primereact/resources/themes/saga-orange/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import { ConnectedRouter } from 'connected-react-router';
import { NotificationFunctions, Notification as ECNotification } from 'concert-ui-library';
import { History } from 'history';
import NotFound from './components/notfound';
import OauthCallback from './containers/auth/oauth-callback';
import Login from './containers/auth/login';
import Nav from './containers/nav/nav';
import Scheduler from './containers/scheduler/scheduler';
import ProtectedRoute from './containers/protected-route';
import ErrorComponent from './components/error';
import { ConfigContext } from './config';
import { ErrorNotificationComponent } from './containers/error/error';
import { Logout } from './containers/auth/logout';
import SettingsComponentWithRouter from './containers/settings/settings';
import { NotificationContext } from './notification-context';
import { MessageCenter } from './containers/secure-messages/message-center';
import { ConversationCenter } from './containers/secure-messages/conversation-center';
import { ROUTES } from './constants';
import { SessionEndedDialog } from './components/session-ended-dialog';

type AppProps = {
    history: History;
};

const App: FunctionComponent<AppProps> = ({ history }: AppProps) => {
    const messages = useRef(null);
    const notificationFunctions = NotificationFunctions(messages);

    return (
        <ConfigContext.Provider value={(window as any).config}>
            <NotificationContext.Provider value={notificationFunctions}>
                <ConnectedRouter history={history}>
                    <div className="App">
                        <SessionEndedDialog />
                        <Nav />
                        <ECNotification messages={messages} />
                        <ErrorNotificationComponent />
                        <Switch>
                            <Route path={ROUTES.LOGIN} component={Login} origin="" />
                            <Route path={ROUTES.OAUTH_CALLBACK} component={OauthCallback} />
                            <Route path={ROUTES.ERROR} component={ErrorComponent} />
                            <Route path={ROUTES.LOGOUT} component={Logout} origin="" />
                            <ProtectedRoute path={ROUTES.SETTINGS} component={SettingsComponentWithRouter} origin="" />
                            <ProtectedRoute path={ROUTES.SCHEDULER} component={Scheduler} origin="" />
                            <ProtectedRoute exact path="/" component={Scheduler} origin="" />
                            <ProtectedRoute exact path={ROUTES.MESSAGING} component={MessageCenter} origin="" />
                            <ProtectedRoute exact path={ROUTES.CONVERSATION} component={ConversationCenter} origin="" />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </ConnectedRouter>
            </NotificationContext.Provider>
        </ConfigContext.Provider>
    );
};

export default App;
