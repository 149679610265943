import { DateTime, Duration } from 'luxon';
import { Episode } from '../../episodes/slice';
import { User } from '../../user/slice';
import { SlotForRender, transformProvidersToRender, transformSlotsForRender } from './scheduler-transform';
import { AvailabilityProviderDetails } from './slice';
import { AvailabilitySettingByDay, AvailabilitySettingTimeslot, Timeslot } from '../../../services/graphql/generated';

export type FilterOptionsList = {
    value: string;
    group?: string;
    nameFunction: () => string;
    evalFunction: (p: AvailabilityProviderDetails) => boolean;
};

export enum FilterGroup {
    AgeFilter = 'AgeFilter',
}

export type Filters = {
    acceptingNewPatients: FilterOptionsList;
    belowTargetCaseload: FilterOptionsList;
    ages6To12: FilterOptionsList;
    ages13To17: FilterOptionsList;
    ages18Plus: FilterOptionsList;
    onsite: FilterOptionsList;
};

export const baseTimeslotFilters: Filters = {
    acceptingNewPatients: {
        value: 'AcceptingNewPatients',
        nameFunction: () => {
            return 'Accepting New Patients';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return p.acceptingNewPatients === true;
        },
    },
    belowTargetCaseload: {
        value: 'BelowTargetCaseload',
        nameFunction: () => {
            return 'Below Target Caseload';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return (p.utilization?.rate || 0) < 100;
        },
    },
    ages6To12: {
        value: 'Ages 6-12',
        group: FilterGroup.AgeFilter,
        nameFunction: () => {
            return 'Ages 6-12';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return p?.specialties ? p.specialties.includes('Ages 6-12') : false;
        },
    },
    ages13To17: {
        value: 'Ages 13-17',
        group: FilterGroup.AgeFilter,
        nameFunction: () => {
            return 'Ages 13-17';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return p?.specialties ? p.specialties.includes('Ages 13-17') : false;
        },
    },
    ages18Plus: {
        value: 'Ages 18+',
        group: FilterGroup.AgeFilter,
        nameFunction: () => {
            return 'Ages 18+';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return p?.specialties ? p.specialties.includes('Ages 18+') : false;
        },
    },
    onsite: {
        value: 'Onsite',
        nameFunction: () => {
            return 'OnSite Availability';
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return p.isOnSitePractice === true;
        },
    },
};

export const createRefineOptionsList = (episode: Episode): FilterOptionsList[] => [
    baseTimeslotFilters.acceptingNewPatients,
    baseTimeslotFilters.belowTargetCaseload,
    baseTimeslotFilters.ages6To12,
    baseTimeslotFilters.ages13To17,
    baseTimeslotFilters.ages18Plus,
    baseTimeslotFilters.onsite,
    {
        value: 'EngagementType',
        nameFunction: () => {
            return `${episode?.engagementType}`;
        },
        evalFunction: (p: AvailabilityProviderDetails) => {
            return episode?.engagementType ? p.engagementTypes.includes(episode?.engagementType) : true;
        },
    },
];

const getSlotsWithinPatientPreferredDays = (
    availabilitySlots: Timeslot[],
    preferredPatientAvailability: AvailabilitySettingByDay | undefined,
    slotDuration: Duration,
): string[] => {
    const patientPreferredAvailabilitySlots = preferredPatientAvailability?.timeslots;
    if (!patientPreferredAvailabilitySlots) return [];

    const slotsWithinPatientPreferredDays: string[] = [];
    patientPreferredAvailabilitySlots.forEach((s: AvailabilitySettingTimeslot) => {
        const format = 'HH:mm:ss';
        const preferredSlotStart = DateTime.fromFormat(s.startTime, format);
        const preferredSlotEnd = DateTime.fromFormat(s.endTime, format);
        availabilitySlots?.forEach((t: Timeslot) => {
            const slotStart = DateTime.fromFormat(t.time || '', format);
            const slotEnd = slotStart.plus(slotDuration);
            if (slotStart >= preferredSlotStart && slotEnd <= preferredSlotEnd)
                slotsWithinPatientPreferredDays.push(t.time || '');
        });
    });
    return slotsWithinPatientPreferredDays;
};

export const refineTimeSlots = (
    refineSelectedOptions: string[],
    episode: Episode,
    slots: Timeslot[],
    isGroupedBy: boolean,
    selectedDate: DateTime,
    preferredPatientAvailability: AvailabilitySettingByDay | undefined,
    slotDuration: Duration,
): User[] | SlotForRender[] => {
    const refineOptionsList = createRefineOptionsList(episode);
    const selectOptionsEvalFunctions = refineSelectedOptions.reduce<((p: AvailabilityProviderDetails) => boolean)[]>(
        (array, selectedOption) => {
            const filteredOption = refineOptionsList.filter(
                (option) => option.value === selectedOption && !option.group,
            );
            if (filteredOption.length > 0) {
                array.push(filteredOption[0].evalFunction);
            }

            return array;
        },
        [],
    );

    const selectOptionsEvalAgeFunctions = refineSelectedOptions.reduce<((p: AvailabilityProviderDetails) => boolean)[]>(
        (array, selectedOption) => {
            const filteredOption = refineOptionsList.filter(
                (option) => option.value === selectedOption && option.group === FilterGroup.AgeFilter,
            );

            if (filteredOption.length > 0) {
                array.push(filteredOption[0].evalFunction);
            }

            return array;
        },
        [],
    );
    let filteredResult: Array<User> | Array<SlotForRender> = new Array<User>();
    const filteredSlots: any = {};
    slots.map((slot: any) => {
        const provider = slot.providerAvailability.filter(
            (p: AvailabilityProviderDetails) =>
                selectOptionsEvalFunctions.every(
                    (selectionOptionEvalFunction) => selectionOptionEvalFunction(p) === true,
                ) &&
                (selectOptionsEvalAgeFunctions.length === 0 ||
                    selectOptionsEvalAgeFunctions.some(
                        (selectionOptionEvalFunction) => selectionOptionEvalFunction(p) === true,
                    )),
        );
        if (provider.length > 0) {
            filteredSlots[slot.time] = { providers: provider };
        }
    });
    const slotsWithinPatientPreferredDays = getSlotsWithinPatientPreferredDays(
        slots,
        preferredPatientAvailability,
        slotDuration,
    );

    if (isGroupedBy) {
        filteredResult = transformProvidersToRender(filteredSlots, selectedDate, slotsWithinPatientPreferredDays);
    } else {
        filteredResult = transformSlotsForRender(filteredSlots, selectedDate, slotsWithinPatientPreferredDays);
    }
    return filteredResult;
};
