import { Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { FunctionComponent } from 'react';

export type SelectionChangeEvent = {
    changedRecords: unknown[];
    newSelectedKeys: unknown;
};

export type SelectedKeysDictionary = {
    [key: string]: { checked: boolean };
};

export type CheckboxTreeProps = {
    nodes: TreeNode[] | undefined;
    selectedKeys: TreeCheckboxSelectionKeys | undefined;
    loading: boolean;
    checkboxField: string;
    expandedKeys?: TreeExpandedKeysType;
    onSelectionChange(event: SelectionChangeEvent): void;
};

export const CheckboxTree: FunctionComponent<CheckboxTreeProps> = ({
    nodes,
    selectedKeys,
    loading,
    checkboxField,
    expandedKeys,
    onSelectionChange,
}) => {
    const updateUserPracticeAvailability = (
        newChanges: TreeCheckboxSelectionKeys,
        previousAvailability: TreeCheckboxSelectionKeys,
    ) => {
        const previousChanged = Object.keys(previousAvailability)
            .filter((nodeKey: string) => {
                const relatedChange = newChanges[nodeKey];

                return !relatedChange;
            })
            .map((nodeId: string) => {
                return {
                    id: nodeId,
                    [checkboxField]: false,
                };
            });
        const newValues = Object.keys(newChanges)
            .filter((nodeKey: string) => {
                const relatedChange = previousAvailability[nodeKey];

                return !relatedChange || relatedChange.checked !== newChanges[nodeKey].checked;
            })
            .map((nodeId: string) => {
                return {
                    id: nodeId,
                    [checkboxField]: newChanges[nodeId].checked,
                };
            });

        const changedRecords = [...previousChanged, ...newValues];
        onSelectionChange({ changedRecords, newSelectedKeys: newChanges });
    };

    return (
        <div>
            <Tree
                value={nodes}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                loading={loading}
                propagateSelectionUp={false}
                filter
                filterMode="lenient"
                expandedKeys={expandedKeys}
                onSelectionChange={(e) => {
                    updateUserPracticeAvailability(
                        e.value as TreeCheckboxSelectionKeys,
                        selectedKeys as TreeCheckboxSelectionKeys,
                    );
                }}
            />
        </div>
    );
};
