/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { reducer as UserReducer } from './containers/user/slice';
import { reducer as AuthReducer } from './containers/auth/slice';
import { reducer as AvailabilityReducer } from './containers/scheduler/availability/slice';
import { reducer as EpisodesReducer } from './containers/episodes/slice';
import { reducer as CalendarEventReducer } from './containers/scheduler/calendar-event/calendar-event-slice';
import { reducer as AppErrorReducer } from './containers/error/slice';
import { reducer as UsersReducer } from './containers/users/slice';
import { reducer as PracticesReducer } from './containers/practices/slice';
import { reducer as LanguageReducer } from './containers/languages/slice';
import { reducer as SchedulerFilterReducer } from './containers/scheduler/scheduler-filter/slice';
import { reducer as SettingsReducer } from './containers/settings/slice';
import { reducer as ConcertSettingsReducer } from './containers/settings/other/slice';
import { reducer as EngagementTypesReducer } from './containers/engagement-types/slice';
import { reducer as schedulerViewSettingsReducer } from './containers/scheduler/scheduler-view-settings/slice';
import { api as graphQLApi } from './services/graphql/concert-base-api';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        user: UserReducer,
        auth: AuthReducer,
        availability: AvailabilityReducer,
        episodes: EpisodesReducer,
        calendarEvent: CalendarEventReducer,
        appErrors: AppErrorReducer,
        users: UsersReducer,
        practices: PracticesReducer,
        languages: LanguageReducer,
        schedulerFilter: SchedulerFilterReducer,
        settings: SettingsReducer,
        concertSettings: ConcertSettingsReducer,
        engagementTypes: EngagementTypesReducer,
        schedulerViewSettings: schedulerViewSettingsReducer,
        [graphQLApi.reducerPath]: graphQLApi.reducer,
        ...injectedReducers,
    });

    return rootReducer;
}
