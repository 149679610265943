import { FC, useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { selectEpisodes } from './selector';
import { Episode, getEpisodes } from './slice';
import './episode-selector.scss';

export type EpisodeSelectorProps = {
    onSelectedEpisode: (episode: Episode | null) => void;
    selectedEpisodeParam: Episode | null;
};

export const EpisodeSelector: FC<EpisodeSelectorProps> = ({ onSelectedEpisode, selectedEpisodeParam }) => {
    const dispatch = useDispatch();
    const episodes: Episode[] = useSelector(selectEpisodes);
    const [selectedEpisode, setSelectedEpisode] = useState(selectedEpisodeParam);
    const [episodeText, setEpisodeText] = useState(selectedEpisode == null ? '' : selectedEpisode.name);

    const searchEpisodes = ({ query }: AutoCompleteCompleteEvent) => {
        dispatch(getEpisodes(query));
    };

    useEffect(() => {
        setSelectedEpisode(selectedEpisodeParam);
        setEpisodeText(selectedEpisodeParam == null ? '' : selectedEpisodeParam.name);
    }, [selectedEpisodeParam]);

    const onChangeEpisode = (newValue: Episode | null) => {
        setSelectedEpisode(newValue);
        onSelectedEpisode(newValue);
    };

    const episodeItemTemplate = (episode: Episode) => {
        const episodeSubHeaders = [episode.practiceName, episode.practiceState.toUpperCase(), episode.providerName];
        return (
            <div className="episode-selector">
                <div>{episode.name}</div>
                <div className="episode-subtext">
                    {episodeSubHeaders
                        .filter((sh) => sh.length > 0)
                        .map((subtext, i) => {
                            return (
                                <span key={episode.id + subtext}>
                                    {i === 0 ? (
                                        ''
                                    ) : (
                                        <i
                                            className="pi period-seperator pi-circle-on
"
                                        />
                                    )}
                                    {subtext}
                                </span>
                            );
                        })}
                </div>
            </div>
        );
    };

    return (
        <AutoComplete
            value={episodeText}
            suggestions={episodes}
            placeholder="Select Episode with (Concert User)"
            delay={400}
            minLength={2}
            onChange={({ value }) => setEpisodeText(value)}
            field="name"
            onSelect={({ value }) => onChangeEpisode(value)}
            className="episode-selector"
            itemTemplate={episodeItemTemplate}
            onClear={() => onChangeEpisode(null)}
            forceSelection
            completeMethod={searchEpisodes}
        />
    );
};
