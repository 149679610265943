import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotificationContext } from '../../notification-context';
import { selectAppError } from './selector';

export const ErrorNotificationComponent: FC = () => {
    const appError = useSelector(selectAppError);
    const notificationContext = useContext(NotificationContext);
    useEffect(() => {
        if (appError === null) {
            return;
        }
        notificationContext.showError((appError as any).customMessage);
    }, [appError]);
    return <div />;
};
