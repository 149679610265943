import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { Loader } from 'concert-ui-library';
import { EpisodeSelector } from '../../episodes/episode-selector';
import { isHydratedSchedulerFilterLoading, selectSchedulerFilter } from './selector';
import { AvailabilityWhoFilterType, changeFilterSelection, hydrateFilterParams, setAvailabilityFilter } from './slice';
import { Episode } from '../../episodes/slice';
import { UserSelector } from '../../users/users-selector';
import { User } from '../../user/slice';
import { PracticeSelector } from '../../practices/practices-selector';
import { Practice } from '../../practices/slice';
import { LanguageSelector } from '../../languages/language-selector';
import { ApiCallStatus } from '../../../services/api-call-status';

export type SchedulerAvailabilityFilterProps = {
    onFilterSet: () => void;
};

export const SchedulerAvailabilityFilter: FC<SchedulerAvailabilityFilterProps> = ({ onFilterSet }) => {
    const availabilityFilter = useSelector(selectSchedulerFilter);
    const isFilterLoading = useSelector(isHydratedSchedulerFilterLoading);
    const [selectedEpisode, setSelectedEpisode] = useState(availabilityFilter.episode);
    const [selectedPractice, setSelectedPractice] = useState(availabilityFilter.practice);
    const [selectedLanguage, setSelectedLanguage] = useState(availabilityFilter.language);
    const [selectedUser, setSelectedUser] = useState(availabilityFilter.user as User[]);
    const [activeTab, setActiveTab] = useState(availabilityFilter.who);

    const dispatch = useDispatch();
    const footerTemplate = () => {
        return (
            <div className="button-footer">
                <Button label="Reset" className="p-button-secondary" onClick={() => resetFilter()} />
                <Button label="OK" className="p-button-primary" onClick={() => setFilter()} />
            </div>
        );
    };

    useEffect(() => {
        setSelectedUser(availabilityFilter.user as User[]);
        setSelectedEpisode(availabilityFilter.episode);
        setSelectedPractice(availabilityFilter.practice);
        setSelectedLanguage(availabilityFilter.language);
        setActiveTab(availabilityFilter.who);
    }, [availabilityFilter]);

    const resetFilter = () => {
        const newAvailabilityFilter = { ...availabilityFilter };
        newAvailabilityFilter.episode = null;
        newAvailabilityFilter.user = [];
        newAvailabilityFilter.practice = null;
        newAvailabilityFilter.language = null;
        newAvailabilityFilter.who = AvailabilityWhoFilterType.User;
        dispatch(changeFilterSelection(false));
        dispatch(setAvailabilityFilter(newAvailabilityFilter));
    };

    const setFilter = () => {
        const newAvailabilityFilter = { ...availabilityFilter };
        newAvailabilityFilter.episode = selectedEpisode;
        newAvailabilityFilter.user = selectedUser;
        newAvailabilityFilter.practice = selectedPractice;
        newAvailabilityFilter.language = selectedLanguage;
        newAvailabilityFilter.who = activeTab;
        dispatch(setAvailabilityFilter(newAvailabilityFilter));
        dispatch(changeFilterSelection(true));
        onFilterSet();
    };

    const onSelectedPractice = (value: Practice) => {
        setSelectedPractice(value);
    };

    const onSelectedLanguage = (value: string | null) => {
        if (value === undefined) {
            value = null;
        }
        setSelectedLanguage(value);
    };

    const onSelectedEpisode = (episode: Episode | null) => {
        if (episode && selectedEpisode && episode.id === selectedEpisode?.id) {
            return;
        }
        setSelectedEpisode(episode);
        if (episode?.ownerId) {
            dispatch(changeFilterSelection(false));
            dispatch(
                hydrateFilterParams({
                    userId: episode?.ownerId,
                    episodeId: episode.id,
                    practiceId: episode?.practiceId,
                    activeTab,
                    language: episode.patientLanguage,
                }),
            );
        }
    };

    return (
        <div className="filter">
            <TabView className="filter-section">
                <TabPanel header="Episode">
                    <EpisodeSelector
                        onSelectedEpisode={(value) => onSelectedEpisode(value)}
                        selectedEpisodeParam={selectedEpisode}
                    />
                </TabPanel>
            </TabView>
            <div className="filter-section">
                {isFilterLoading !== ApiCallStatus.loading ? (
                    <>
                        <div className="filter-radios">
                            <div className="field-radiobutton">
                                <RadioButton
                                    inputId="whoFilterRbUser"
                                    name="concertUserRB"
                                    value={AvailabilityWhoFilterType.User}
                                    onChange={(e) => setActiveTab(e.value as AvailabilityWhoFilterType)}
                                    checked={activeTab === AvailabilityWhoFilterType.User}
                                />
                                <label htmlFor="whoFilterRbUser">Concert User</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton
                                    inputId="whoFilterRbTeam"
                                    name="teamRB"
                                    value={AvailabilityWhoFilterType.Team}
                                    onChange={(e) => setActiveTab(e.value as AvailabilityWhoFilterType)}
                                    checked={activeTab === AvailabilityWhoFilterType.Team}
                                />
                                <label htmlFor="whoFilterRbTeam">Team</label>
                            </div>
                        </div>
                        {activeTab === AvailabilityWhoFilterType.User ? (
                            <UserSelector
                                onSelectedUser={(value) => {
                                    setSelectedUser(value as User[]);
                                }}
                                selectedUserParam={selectedUser}
                                multiple
                            />
                        ) : (
                            <>
                                <PracticeSelector
                                    onSelectedPractice={(value) => onSelectedPractice(value as Practice)}
                                    selectedPracticeParam={selectedPractice}
                                />
                                <LanguageSelector
                                    onSelectedLanguage={(value) => onSelectedLanguage(value as string)}
                                    selectedLanguageParam={selectedLanguage}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div className="filter-loader">
                        <Loader />
                    </div>
                )}
            </div>
            {footerTemplate()}
        </div>
    );
};
