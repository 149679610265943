import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { unAuthenticate } from '../containers/auth/slice';
import { AppDispatch } from '../store';

export const addInterceptors = (dispatch: AppDispatch): void => {
    axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            const { origin } = new URL(config.url ? config.url : '');
            const allowedOrigins = [axios.defaults.baseURL];
            const token = sessionStorage.getItem('chToken');
            if (allowedOrigins.includes(origin)) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            const status = error.response?.status;
            if (status === 401) {
                dispatch(unAuthenticate());
            }
            return Promise.reject(error);
        },
    );
};

export const initializeAxiosConfigDefaults = (): void => {
    axios.defaults.baseURL = (window as any).config.apiUrl;
};
