import { DateTime } from 'luxon';
import { SchedulingDateFunctions } from '../../../services/scheduling-date-fns';
import { User } from '../../user/slice';
import type { SlotButtonAction } from './provider-selector';
import { AvailabilityProviderDetails, AvailabilitySlotDetails } from './slice';

export type SlotForRender = {
    label: string;
    value: SlotButtonAction | string;
    slot?: AvailabilitySlotDetails;
    isWithinPreferredHours: boolean;
    providers?: AvailabilityProviderDetails[];
};

export type SlotMap = {
    [key: string]: AvailabilitySlotDetails;
};

export type SlotProvidersMap = {
    [key: string]: User;
};

export const transformSlotsForRender = (
    slots: SlotMap,
    selectedDate: DateTime,
    preferredPatientAvailability: string[],
): SlotForRender[] => {
    return Object.entries(slots).map(([k, v]) =>
        mapSlotForRender([k, v], selectedDate, false, preferredPatientAvailability),
    );
};

export const extractSlot = (
    refinedSlotsList: SlotForRender[] | User[],
    slot: string,
    isGroupedBy: boolean,
    providerFilter?: string,
): AvailabilitySlotDetails => {
    return isGroupedBy && providerFilter
        ? {
              providers: (refinedSlotsList as any[])
                  .find((p: User) => p.id === providerFilter)
                  .slots.find((p: SlotForRender) => p.value === slot)
                  .slot.providers.filter((p: AvailabilityProviderDetails) => p.provider.id === providerFilter),
          }
        : (refinedSlotsList as any[]).find((p: SlotForRender) => p.value === slot).slot;
};

export const providerByCapacitySorterDescending = (providerA: User, providerB: User): number => {
    let capacityA = providerA.capacity?.timeslotsAvailableInNearFuture ?? 0;
    let capacityB = providerB.capacity?.timeslotsAvailableInNearFuture ?? 0;
    if (providerA.refProvider && providerB.refProvider) {
        capacityA = providerA.refProvider.capacity?.timeslotsAvailableInNearFuture ?? 0;
        capacityB = providerB.refProvider.capacity?.timeslotsAvailableInNearFuture ?? 0;
    }
    return capacityB - capacityA;
};

const mapSlotForRender = (
    [k, v]: [string, AvailabilitySlotDetails],
    selectedDate: DateTime,
    isGroupedBy: boolean,
    PreferredPatientAvailability: string[],
    providerFilter?: string,
): SlotForRender => {
    return {
        label: SchedulingDateFunctions.getTimeDisplayStringHourMinutes(selectedDate, k),
        value: k,
        slot: v,
        isWithinPreferredHours: PreferredPatientAvailability.includes(k),
        providers: isGroupedBy
            ? (v as AvailabilitySlotDetails).providers.filter((p) => p.provider.id === providerFilter)
            : (v as AvailabilitySlotDetails).providers,
    };
};
export const transformProvidersToRender = (
    slots: SlotMap,
    selectedDate: DateTime,
    PreferredPatientAvailability: string[],
): User[] => {
    const transformedProviders: User[] = [];
    Object.entries(slots).map(([k, v]: [string, AvailabilitySlotDetails]) => {
        v.providers.map((provider: AvailabilityProviderDetails) => {
            const transformedProvider = transformedProviders.find((p) => p.id === provider.provider.id);
            if (!transformedProvider) {
                transformedProviders.push({
                    ...provider.provider,
                    acceptingNewPatients: provider.acceptingNewPatients,
                    isOnSitePractice: provider.isOnSitePractice,
                    refProvider: provider,
                    notAcceptingType: provider.notAcceptingType,
                    slots: [],
                });
                transformedProviders
                    .find((p: User) => p.id === provider.provider.id)
                    ?.slots?.push(
                        mapSlotForRender(
                            [k, v],
                            selectedDate,
                            true,
                            PreferredPatientAvailability,
                            provider.provider.id,
                        ),
                    );
            } else {
                transformedProvider?.slots?.push(
                    mapSlotForRender([k, v], selectedDate, true, PreferredPatientAvailability, provider.provider.id),
                );
            }
        });
    });
    return transformedProviders.sort(providerByCapacitySorterDescending);
};
