import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { selectIsAuthenticated } from './auth/selector';

export interface ProtectedRouteProps extends RouteProps {
    origin?: string;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (props) => {
    const currentLocation = useLocation();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const redirectPath = '/login';
    const currentLocationUrl = `${currentLocation.pathname}${currentLocation.search}`;
    if (!isAuthenticated) {
        const renderComponent = () => {
            return <Redirect to={{ pathname: redirectPath, state: { origin: currentLocationUrl } }} />;
        };
        return <Route {...props} component={renderComponent} render={undefined} origin={currentLocationUrl} />;
    }
    return <Route {...props} />;
};

export default ProtectedRoute;
