export const PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE =
    'The patient cannot be messaged: No communication method available.';
export const EPISODE_INACTIVE_ERROR_MESSAGE =
    'Your message was not saved. New messages are not allowed for closed episodes.';
export const NOT_ALLOWED_TO_SEND_MESSAGES =
    'Your message was not saved. Please contact support for permission to send messages.';
export const EPISODE_INACTIVE_WARNING_MESSAGE = 'New messages are not allowed for closed episodes.';
export const ERROR_CODES = {
    PATIENT_CANT_RECEIVE_COMMUNICATIONS: 'PATIENT_CANT_RECEIVE_COMMUNICATIONS',
    INACTIVE_EPISODE: 'INACTIVE_EPISODE',
    USER_MISSING_SECURE_MESSAGING_PERMISSION: 'USER_MISSING_SECURE_MESSAGING_PERMISSION',
};
export const SEND_SECURE_MESSAGES_CUSTOM_PERMISSION = 'Secure_Messaging_Edit';
export const MESSAGE_SUCCESSFULLY_SUBMITTED = 'Message and Time Entry saved';
