import { SelectButton } from 'primereact/selectbutton';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime as luxonDateTime } from 'luxon';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SchedulingDateFunctions } from '../../../services/scheduling-date-fns';
import { UtilizationForUser, getCircleColor } from '../user-availability-info/utiization-calc';
import { AvailabilityCircleColor } from '../../../enums/availabilityCircleColor';
import { selectGroupByUserSetting } from '../scheduler-view-settings/selector';
import { swapGroupByUserSetting } from '../scheduler-view-settings/slice';
import { selectSchedulerFilter } from '../scheduler-filter/selector';
import { ProviderSelector, SlotButtonAction } from './provider-selector';
import { Constants } from './constants';
import { UserAvailabilityInfoPanel } from '../user-availability-info/user-availability-info-panel';
import { SlotForRender, SlotProvidersMap } from './scheduler-transform';
import { User } from '../../user/slice';

export type SchedulerTimeSlotProps = {
    selectedDate: luxonDateTime;
    slots: User[] | SlotForRender[];
    displayUtilizationOnSlot: boolean;
    onScheduleTimeSlotRequest: (timeSlotSelected: string, providerFilter?: string) => void;
};

export const SchedulerTimeslots: FC<SchedulerTimeSlotProps> = ({
    selectedDate,
    slots,
    onScheduleTimeSlotRequest,
    displayUtilizationOnSlot,
}) => {
    const selectedDateString = SchedulingDateFunctions.getDateOnlyDisplayString(selectedDate);
    const timezoneString = SchedulingDateFunctions.getTimezoneDisplayStringFromDate(selectedDate);
    const currentTimeString = SchedulingDateFunctions.getCurrentTimeDisplayStringInTimezone(selectedDate.zoneName);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<SlotButtonAction | null | string>(null);
    const filter: any | null = useSelector(selectSchedulerFilter);
    const isGroupedByState = useSelector(selectGroupByUserSetting);
    const dispatch = useDispatch();

    const userItemEls = useRef<Map<string, OverlayPanel | null>>(new Map());

    const providersRender = () =>
        Object.entries(providersToRender).map(([k, v]: any) => {
            return (
                <ProviderSelector
                    key={k}
                    availabilityProviderDetail={v}
                    filter={filter.episode}
                    userItemEls={userItemEls}
                    showSlots
                    selectedTimeSlot={selectedTimeSlot}
                    selectTimeSlot={selectTimeSlot}
                    itemTemplate={itemTemplate}
                />
            );
        });

    const [slotsToRender, setSlotsToRender] = useState<SlotForRender[]>([]);
    const [providersToRender, setProvidersToRender] = useState<SlotProvidersMap>({});

    const selectTimeSlot = (timeSlotSelected: SlotButtonAction | string, providerFilter?: string) => {
        if (typeof timeSlotSelected === 'string') {
            onScheduleTimeSlotRequest(String(timeSlotSelected), providerFilter);
            setSelectedTimeSlot(timeSlotSelected);
            return;
        }
        const spreadSlots: any = {};
        slots.map((p: any) => {
            spreadSlots[p.id] = { ...p, slots: [...p.slots] };
        });
        const slotsExpandedCollapsed = expandCollapseSlots(
            timeSlotSelected.action,
            timeSlotSelected.provider,
            spreadSlots,
        );
        setProvidersToRender(slotsExpandedCollapsed);
    };

    const itemTemplate = (slot: SlotForRender) => {
        if (typeof slot.value !== 'string') {
            return (
                <span className="slot-text more-less-button">
                    <i
                        className={`pi pi-${
                            slot.label === Constants.moreButtonAction ? 'angle-double-down icon' : 'angle-double-up'
                        }`}
                    />
                    {slot.label}
                </span>
            );
        }
        const classForUtil =
            displayUtilizationOnSlot &&
            slot?.providers?.every(
                (p: any) =>
                    getCircleColor((p.utilization as UtilizationForUser).messageCategory) ===
                    AvailabilityCircleColor.red,
            )
                ? 'slot-text util-red'
                : 'slot-text';

        return <span className={classForUtil + (slot.isWithinPreferredHours ? ' bold-slot' : '')}>{slot.label}</span>;
    };

    const expandCollapseSlots = (action: string, timeSlotSelected: string, slotsToSlice: any) => {
        const stateProvider = { ...providersToRender };
        const readyToSliceProvider = slotsToSlice[timeSlotSelected];
        if (action === Constants.moreButtonAction) {
            readyToSliceProvider.slots.push({
                label: Constants.lessButtonAction,
                value: { action: Constants.lessButtonAction, provider: readyToSliceProvider.id },
            });
        } else {
            readyToSliceProvider.slots = readyToSliceProvider.slots.slice(0, Constants.slotLimit);
            readyToSliceProvider.slots.push({
                label: Constants.moreButtonAction,
                value: { action: Constants.moreButtonAction, provider: readyToSliceProvider.id },
            });
        }
        stateProvider[timeSlotSelected].slots = [...readyToSliceProvider.slots];
        return stateProvider;
    };

    useEffect(() => {
        if (!isGroupedByState) {
            setSlotsToRender(slots as SlotForRender[]);
        } else if (slots.length) {
            const slotsAdjustedForLimit: SlotProvidersMap = {};
            (slots as User[])?.map((provider: User) => {
                const copiedProvider = { ...provider };
                if ((provider?.slots?.length ?? 0) > Constants.slotLimit) {
                    copiedProvider.slots = copiedProvider?.slots?.slice(0, Constants.slotLimit);
                    copiedProvider?.slots?.push({
                        label: Constants.moreButtonAction,
                        value: { action: Constants.moreButtonAction, provider: provider.id, value: '' },
                        isWithinPreferredHours: false,
                    });
                }
                slotsAdjustedForLimit[copiedProvider.id] = copiedProvider;
            });
            setProvidersToRender(slotsAdjustedForLimit);
        }
    }, [slots]);

    return (
        <div>
            <div className="scheduler-timeslot-header">
                <div className="left-container">
                    <h3>{selectedDateString}</h3>
                    <div className="subtitle-block">
                        <div>
                            All times {timezoneString} (Now {currentTimeString})
                        </div>
                    </div>
                </div>
                <div className="right-container">
                    <div>
                        <input
                            data-testid="isGroupedById"
                            checked={isGroupedByState}
                            onChange={() => dispatch(swapGroupByUserSetting())}
                            type="checkbox"
                            id="isGroupedById"
                        />
                        <label htmlFor="isGroupedById"> Group by user</label>
                    </div>
                </div>
            </div>
            {Object.entries(providersToRender).length !== 0 && (
                <div>
                    <UserAvailabilityInfoPanel
                        availabilityProviderDetails={Object.entries(providersToRender).map(([k, v]: [string, User]) => {
                            return v.refProvider;
                        })}
                        episode={filter.episode}
                        userItemEls={userItemEls}
                        showCloseButton
                        hasBorder
                    />
                </div>
            )}

            <Divider type="solid" />
            {slots.length === 0 && <div className="timeslot-selector">No availability found on selected date. </div>}
            {isGroupedByState === true && slots.length !== 0
                ? providersRender()
                : slots.length !== 0 && (
                      <SelectButton
                          options={slotsToRender}
                          value={selectedTimeSlot}
                          itemTemplate={itemTemplate}
                          optionLabel="label"
                          optionValue="value"
                          className="timeslot-selector"
                          onChange={(e) => selectTimeSlot(e.value)}
                      />
                  )}
        </div>
    );
};
