import { put, takeEvery } from 'redux-saga/effects';
import axios, { Axios, AxiosError } from 'axios';
import { ErrorConstants } from '../../error/constants';
import {
    AvailabilityWhoFilterType,
    hydrateFilterParams,
    hydrateFilterParamsComplete,
    setAvailabilityFilter,
} from './slice';

function* hydrateFilterParamsFromApi(action: any) {
    try {
        const { payload } = action;
        const requestMap: Map<string, unknown> = new Map<string, unknown>();
        let requestIndex = 0;
        if (!payload.episodeId && !payload.practiceId && !payload.userId && !payload.language) {
            yield put(
                setAvailabilityFilter({
                    user: null,
                    who: AvailabilityWhoFilterType.User,
                }),
            );
            yield put(hydrateFilterParamsComplete({}));
            return;
        }

        if (payload.episodeId) {
            requestMap.set('episode', {
                index: requestIndex++,
                request: axios.get(`${axios.defaults.baseURL}/episodes/${payload.episodeId}`),
            });
        }
        if (payload.practiceId) {
            requestMap.set('practice', {
                index: requestIndex++,
                request: axios.get(`${axios.defaults.baseURL}/practices/${payload.practiceId}`),
            });
        }
        if (payload.userId) {
            requestMap.set('user', {
                index: requestIndex++,
                request: axios.get(`${axios.defaults.baseURL}/users/${payload.userId}`),
            });
        }
        const { ...responses } = yield axios.all(Array.from(requestMap.values()).map((r: any) => r.request));
        const availabilityFilter = {
            episode: null as any,
            practice: null as any,
            user: null as any,
            language: null as any,
            who: null as any,
        };

        if (payload.activeTab !== null) {
            availabilityFilter.who = payload.activeTab;
        }

        if (payload.practiceId) {
            availabilityFilter.practice = responses[(requestMap.get('practice') as any).index].data;
            availabilityFilter.language = payload.language ? payload.language : undefined;
            availabilityFilter.who == null && (availabilityFilter.who = AvailabilityWhoFilterType.Team);
        }

        if (payload.userId) {
            availabilityFilter.user = [responses[(requestMap.get('user') as any).index].data];
            availabilityFilter.who == null && (availabilityFilter.who = AvailabilityWhoFilterType.User);
        }

        if (payload.language) {
            availabilityFilter.language = payload.language;
            availabilityFilter.who == null && (availabilityFilter.who = AvailabilityWhoFilterType.Team);
        }

        if (payload.episodeId) {
            availabilityFilter.episode = responses[(requestMap.get('episode') as any).index].data;

            if (availabilityFilter.language == null) {
                availabilityFilter.language = availabilityFilter.episode.patientLanguage;
            }

            if (availabilityFilter.practice == null) {
                availabilityFilter.practice = {
                    name: availabilityFilter.episode.practiceName,
                    id: availabilityFilter.episode.practiceId,
                };
            }

            if (availabilityFilter.user == null) {
                const { data } = yield axios.get(
                    `${axios.defaults.baseURL}/users/${availabilityFilter.episode.ownerId}`,
                );
                availabilityFilter.user = [data];
            }
        }

        yield put(setAvailabilityFilter(availabilityFilter));
        yield put(hydrateFilterParamsComplete({}));
    } catch (error) {
        let errorMessage = '';
        if (error instanceof Error) {
            errorMessage = error.message;
        } else if (error instanceof AxiosError) {
            errorMessage = error.message;
        }
        const apiError = {
            message: errorMessage,
            customMessage: `Error getting filters information. ${ErrorConstants.UserCheckMessage}`,
        };
        yield put(hydrateFilterParamsComplete({ error: apiError }));
    }
}

export default function* watchHydrateSchedulerFilter(): Generator {
    yield takeEvery(hydrateFilterParams, hydrateFilterParamsFromApi);
}
