import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
    getUserConcertSettings,
    getUserConcertSettingsFailure,
    getUserConcertSettingsSuccess,
    saveUserPractices,
    saveUserPracticesFailure,
    saveUserPracticesSuccess,
    saveAcceptingNewPatients,
    saveAcceptingNewPatientsFailure,
    saveAcceptingNewPatientsSuccess,
    saveEngagementTypes,
    saveEngagementTypesSuccess,
    saveEngagementTypesFailure,
    UserPracticesSavingOperation,
} from './slice';
import { ErrorConstants } from '../../error/constants';
import { Action, ErrorMessage, ErrorMessagePayload } from '../../../common-types';
import { Practice } from '../../practices/slice';
import { SelectedKeysDictionary } from '../../../components/checkbox-tree';

export type SettingsPayload = {
    targetCaseLoad: number;
    engagementTypes: string[];
    userId: string;
};

export type SavingUserPracticesPayload = {
    type: string;
    selections: SelectedKeysDictionary;
    userPractices: Practice[];
};

function* getUserSettingsFromApi(action: Action) {
    try {
        const { payload } = action;
        const { data } = yield axios.get(`${axios.defaults.baseURL}/user-settings/${payload}`);
        yield put(getUserConcertSettingsSuccess({ data }));
    } catch (error: unknown) {
        const errorMessage = error as ErrorMessage;
        errorMessage.customMessage = null;
        const apiError = {
            message: errorMessage.message,
            customMessage: `Error getting user settings. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(getUserConcertSettingsFailure({ error: apiError }));
    }
}

function* saveAcceptingNewPatientsSettingFromApi(action: Action) {
    yield saveSettingsFromApi(action, saveAcceptingNewPatientsSuccess, saveAcceptingNewPatientsFailure);
}

function* saveEngagementTypesSettingFromApi(action: Action) {
    yield saveSettingsFromApi(action, saveEngagementTypesSuccess, saveEngagementTypesFailure);
}

function* saveSettingsFromApi(
    action: Action,
    successAction: ActionCreatorWithPayload<SettingsPayload, string>,
    failureAction: ActionCreatorWithPayload<ErrorMessagePayload, string>,
) {
    try {
        const payload = action.payload as SettingsPayload;
        yield axios.post(`${axios.defaults.baseURL}/user-settings/${payload.userId}`, { ...payload });
        yield put(successAction(payload));
    } catch (error: unknown) {
        const errorMessage = error as ErrorMessage;
        errorMessage.customMessage = null;
        const apiError = {
            message: errorMessage.message,
            customMessage: `Error saving user settings. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(failureAction({ error: apiError }));
    }
}

function* saveUserPracticesApi(action: Action) {
    try {
        const payload = action.payload as SavingUserPracticesPayload;
        if (payload.type === UserPracticesSavingOperation.insert) {
            yield axios.post(`${axios.defaults.baseURL}/user-practices`, payload.userPractices);
        } else {
            yield axios.patch(`${axios.defaults.baseURL}/user-practices`, payload.userPractices);
        }
        yield put(saveUserPracticesSuccess(payload));
    } catch (error: unknown) {
        const errorMessage = error as ErrorMessage;
        errorMessage.customMessage = null;
        const apiError = {
            message: errorMessage.message,
            customMessage: `Error saving user practice settings. ${errorMessage.response?.data.message} `,
        };
        yield put(saveUserPracticesFailure({ error: apiError }));
    }
}

export function* watchGetUserConcertSettings(): Generator {
    yield takeEvery(getUserConcertSettings, getUserSettingsFromApi);
}

export function* watchSaveUserConcertSettings(): Generator {
    yield takeEvery(saveAcceptingNewPatients, saveAcceptingNewPatientsSettingFromApi);
    yield takeEvery(saveEngagementTypes, saveEngagementTypesSettingFromApi);
}

export function* watchSaveUserPractices(): Generator {
    yield takeEvery(saveUserPractices, saveUserPracticesApi);
}
