import { FunctionComponent, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'concert-ui-library';
import { useSelector } from 'react-redux';
import { ConfigContext, ECConfig, SFConfig } from '../../config';
import { AuthService } from '../../services/auth-service';
import { selectLoginRedirectLocation } from './selector';

interface LocationState {
    origin: string;
}

const Login: FunctionComponent = () => {
    const loginRedirectLocation = useSelector(selectLoginRedirectLocation);

    const origin = useLocation<LocationState>().state?.origin || '/';
    const config = useContext(ConfigContext);
    const defaultConfig: SFConfig = {
        loginUrl: '',
        redirectUri: '',
        clientId: '',
    };
    const sfConfig: SFConfig = config === null ? defaultConfig : (config as ECConfig).sfSettings;

    const getGlobalRedirectLocation = (): string | null =>
        loginRedirectLocation ? `${loginRedirectLocation.pathname}${loginRedirectLocation.search}` : null;

    useEffect(() => {
        const loginWithSalesforce = () => {
            const sfRedirectUrl = AuthService.authorizewithSF(getGlobalRedirectLocation() ?? origin, sfConfig);
            window.location.assign(sfRedirectUrl);
        };
        loginWithSalesforce();
    }, []);

    return <Loader />;
};

export default Login;
