import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuItem } from 'primereact/menuitem';
import { Menubar } from 'primereact/menubar';
import { Avatar } from 'primereact/avatar';
import { selectUser } from '../user/selector';
import { User } from '../user/slice';
import logo from '../../assets/chlogo.svg';
import './nav.scss';

const Nav: FunctionComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const navigateToPath = (path: string) => {
        history.push(path);
    };
    const selectMenuItem = (path: string) => {
        navigateToPath(path);
    };
    const items: MenuItem[] = [
        {
            label: 'Scheduling',
            command: () => selectMenuItem('/scheduler'),
            className: location.pathname === '/scheduler' || location.pathname === '/' ? 'selected' : 'none',
        },
        {
            label: 'Settings',
            command: () => selectMenuItem('/settings'),
            className: location.pathname === '/settings' ? 'selected' : 'none',
        },
        {
            label: 'Logout',
            command: () => selectMenuItem('/logout'),
            className: location.pathname === '/logout' ? 'selected' : 'none',
        },
    ];
    const user: User | null = useSelector(selectUser);
    const start = () => {
        return (
            <div className="logo-with-spacer">
                <img alt="logo" src={logo} height="40" className="logo" />
            </div>
        );
    };
    const end = () => {
        if (user === null) {
            return '';
        }
        return <Avatar label={user.initials} size="large" shape="circle" />;
    };
    return (
        <div className="top-nav">
            <div className="app-container">
                <Menubar model={items} start={start} end={end} />
            </div>
        </div>
    );
};

export default Nav;
