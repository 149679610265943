export const Constants = {
    slotLimit: 18,
    moreButtonAction: 'more',
    lessButtonAction: 'less',
};

export const AVAILABILITY_ERROR_MESSAGES = {
    ERROR_GETTING_AVAILABILITY: 'Error getting calendar availability.',
    APPOINTMENT_NO_LONGER_AVAILABLE:
        "The time you've selected is no longer available. Please choose from remaining available times or select another day.",
};
