import { FC } from 'react';
import * as React from 'react';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import { Loader, AvailabilityDaysViewer } from 'concert-ui-library';
import calculateUtilization, {
    getCircleColor,
    isEpisodeOwner,
    isNotAvailableForEpisodeEngagementType,
    UtilizationLegendEnum,
    UtilizationForUser,
    utilizationLegendConstants,
    UtilizationLegendCategoryEnum,
} from './utiization-calc';
import { Episode } from '../../episodes/slice';
import { AvailabilityProviderDetails } from '../availability/slice';
import { splitUrl } from '../../../util';
import {
    AvailabilitySettingByDay,
    AvailabilitySettingsCategoryGroup,
    useLazyGetGeneralUserAvailabilitySettingsByDayQuery,
    AvailabilitySettingTimeslot,
} from '../../../services/graphql/generated';
import { SchedulingDateFunctions } from '../../../services/scheduling-date-fns';

export type UserAvailabilityInfoPanelProps = {
    episode: Episode | null;
    availabilityProviderDetails: AvailabilityProviderDetails[];
    showCloseButton: boolean;
    userItemEls: React.MutableRefObject<Map<string, OverlayPanel | null>>;
};
export const UserAvailabilityInfoPanel: FC<any> = ({
    episode,
    availabilityProviderDetails,
    showCloseButton,
    userItemEls,
}) => {
    const [getAvailabilitySettings, { data: settings, isFetching: isAvailabilityLoading }] =
        useLazyGetGeneralUserAvailabilitySettingsByDayQuery();

    const convertTimeslotsToPatientTZ = (
        slots: AvailabilitySettingTimeslot[],
        patientTZ: string,
        providerTZ: string,
    ) => {
        return slots.map((s) => {
            const startDateTimeInProviderTz = SchedulingDateFunctions.getLuxonDateTimeFromDateString(
                s.startTime,
                'hh:mm:ss',
            ).setZone(providerTZ, { keepLocalTime: true });

            const endDateTimeInProviderTz = SchedulingDateFunctions.getLuxonDateTimeFromDateString(
                s.endTime,
                'hh:mm:ss',
            ).setZone(providerTZ, { keepLocalTime: true });

            return {
                ...s,
                startTime: startDateTimeInProviderTz.setZone(patientTZ).toFormat('HH:mm:ss'),
                endTime: endDateTimeInProviderTz.setZone(patientTZ).toFormat('HH:mm:ss'),
            };
        });
    };
    return availabilityProviderDetails.map((availabilityProviderDetail: AvailabilityProviderDetails) => {
        const user = {
            ...availabilityProviderDetail.provider,
            acceptingNewPatients: availabilityProviderDetail.acceptingNewPatients,
            notAcceptingType: availabilityProviderDetail.notAcceptingType,
        };

        const renderAvailability = (availability: AvailabilitySettingsCategoryGroup) => {
            const availabilityByDay = availability?.byDay ?? [];
            const patientTZ = episode?.timezone;
            const days = availabilityByDay.map((day: AvailabilitySettingByDay) =>
                convertTimeslotsToPatientTZ(day.timeslots, patientTZ, availabilityProviderDetail.provider.timezone),
            );
            return <AvailabilityDaysViewer days={days} />;
        };

        const util: UtilizationForUser = calculateUtilization(user, episode);
        const utilCircleClassName = getCircleColor(util.messageCategory);
        const utilMessage: string | undefined =
            util.messageCategory === UtilizationLegendCategoryEnum.Unavailable
                ? 'Consider alternatives for new patients, if possible.'
                : utilizationLegendConstants.get(UtilizationLegendEnum[util.category]);

        return (
            <OverlayPanel
                onShow={() =>
                    getAvailabilitySettings(
                        {
                            request: { userIds: [user.id] },
                        },
                        true,
                    )
                }
                ref={(element) => {
                    userItemEls.current.set(user.id, element);
                }}
                className="overlaypanel-border user-detail-overlay"
                key={user.id}
                showCloseIcon={showCloseButton}
            >
                <div className="profile-header">
                    <Avatar image={user.photoUrl} size="xlarge" shape="circle" />
                    <div className="profile-header-right">
                        <div>
                            <b>{user.nameWithSuffix}</b>
                        </div>
                        <div>{user.title}</div>
                        <div>{splitUrl(user.doxyLink)}</div>
                        <div>{user.phone}</div>
                    </div>
                </div>
                <Divider />
                <div className="profile-details">
                    <div className="profile-availability-info">
                        <div>
                            <b>
                                General Availability (
                                {SchedulingDateFunctions.getCurrentShortTimezone(episode.timezone)}):
                            </b>
                        </div>
                        {isAvailabilityLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                {' '}
                                {renderAvailability(
                                    settings?.getAvailabilitySettings?.general as AvailabilitySettingsCategoryGroup,
                                )}{' '}
                            </div>
                        )}
                    </div>
                    <Divider />
                    {user.languages !== '' && user.languages !== null ? (
                        <div>
                            <b>Languages:</b> {user.languages}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="profile-availability-info">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className={`util pi pi-circle-on ${utilCircleClassName}`} />
                            <div className="wrap">{utilMessage}</div>
                        </div>
                        {availabilityProviderDetail.isOnSitePractice === true && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <i className="util pi pi-map-marker" title="On Site" />
                                <span>On Site At {episode?.practiceName || ' this account'}</span>
                            </div>
                        )}
                        {isNotAvailableForEpisodeEngagementType(availabilityProviderDetail, episode as Episode) ===
                            true && (
                            <Message
                                severity="error"
                                text={`Not available for ${(episode as Episode)?.engagementType}`}
                            />
                        )}
                        {util.messageCategory === UtilizationLegendCategoryEnum.Unavailable && (
                            <Message
                                severity="error"
                                text={utilizationLegendConstants.get(UtilizationLegendEnum[util.category])}
                            />
                        )}
                        {isEpisodeOwner(availabilityProviderDetail, episode) && (
                            <Message severity="info" text="Currently assigned to this episode." />
                        )}
                    </div>
                </div>
                <Divider />
                <div className="profile-availability-info">
                    {user.numOfActiveEpisodes || 0} total active episodes / {user.targetCaseLoad} target
                </div>
                <div className="profile-availability-info">
                    {user.numOfActiveEpisodesForPractice || 0} at {episode?.practiceName || ' this account'}
                </div>
            </OverlayPanel>
        );
    });
};
