import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';
import { User } from '../user/slice';
import { AvailabilitySettingByDate, AvailabilitySettingByDay } from '../../services/graphql/generated';

export const AvailabilityCategory = {
    General: 'General',
    Site: 'Site',
};

export const AvailabilityType = {
    ByDate: 'ByDate',
    ByDay: 'ByDay',
};
export enum AvailabilityOwnerType {
    PATIENT = 'patient',
    USER = 'user',
}
export const dayOfWeekDisplay: Map<string, string> = new Map<string, string>([
    ['Monday', 'Mon'],
    ['Tuesday', 'Tue'],
    ['Wednesday', 'Wed'],
    ['Thursday', 'Thu'],
    ['Friday', 'Fri'],
    ['Saturday', 'Sat'],
    ['Sunday', 'Sun'],
]);
interface ExtendedAvailabilitySettingByDate extends Omit<AvailabilitySettingByDate, 'date'> {
    date: string | null;
}
export interface AvailabilitySettingCategoryMap {
    availabilityType: string;
    value: AvailabilitySettingByDay | ExtendedAvailabilitySettingByDate;
}

export const initialState = {
    data: {} as any,
    settingsUser: {} as User,
    error: null,
    getStatus: ApiCallStatus.idle,
    saveStatus: ApiCallStatus.idle,
    getSettingsUserStatus: ApiCallStatus.idle,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getSettingsUser(state, action) {
            state.getSettingsUserStatus = ApiCallStatus.loading;
            return state;
        },
        getSettingsUserSuccess(state, action) {
            state.getSettingsUserStatus = ApiCallStatus.succeeded;
            state.settingsUser = action.payload;
        },
        getSettingsUserFailure(state, action) {
            state.getStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
        getSettings(state, action) {
            state.getStatus = ApiCallStatus.loading;
            return state;
        },
        getSettingsSuccess(state, action) {
            state.getStatus = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        getSettingsFailure(state, action) {
            state.getStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
        saveSettings(state, action) {
            state.saveStatus = ApiCallStatus.loading;
            return state;
        },
        saveSettingsSuccess(state, action) {
            state.saveStatus = ApiCallStatus.succeeded;
            return state;
        },
        saveSettingsFailure(state, action) {
            state.saveStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
            return state;
        },
        clearSaveSettingsStatus(state) {
            state.saveStatus = ApiCallStatus.idle;
            return state;
        },
    },
});

export const {
    getSettingsSuccess,
    getSettingsFailure,
    getSettings,
    saveSettingsSuccess,
    saveSettingsFailure,
    saveSettings,
    clearSaveSettingsStatus,
    getSettingsUser,
    getSettingsUserSuccess,
    getSettingsUserFailure,
} = settingsSlice.actions;

export const { reducer } = settingsSlice;
