import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { changeAuthState, unAuthenticate } from '../containers/auth/slice';

function* handleUnAuthorizedError() {
    yield put(push('/login', { origin: '' }));
    yield put(changeAuthState(false));
}

export default function* watchUnAuthorizedErrors(): Generator {
    yield takeEvery(unAuthenticate, handleUnAuthorizedError);
}
