import { FC, useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from './selector';
import { getUsers } from './slice';
import './users-selector.scss';
import { User } from '../user/slice';

export type UserSelectorProps = {
    onSelectedUser: (value: User | User[]) => void;
    selectedUserParam: User | User[] | null;
    multiple?: boolean;
};

export const UserSelector: FC<UserSelectorProps> = ({ onSelectedUser, selectedUserParam, multiple }) => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const [selectedUser, setSelectedUser] = useState<User | User[] | null | string>(null);
    const [userSuggestions, setUserSuggestions] = useState<User[]>(users);

    const searchUsers = ({ query }: any) => {
        dispatch(getUsers(query));
    };

    useEffect(() => {
        setSelectedUser(
            selectedUserParam == null || (selectedUserParam as User[])[0] === null ? [] : selectedUserParam,
        );
    }, [selectedUserParam]);

    useEffect(() => {
        setUserSuggestions(users);
    }, [users]);

    const onChangeUser = (event: any, newValue: any) => {
        if (newValue === null) {
            setSelectedUser(null);
            return;
        }
        if (typeof newValue === 'string') {
            setSelectedUser(newValue);
            return;
        }
        setSelectedUser(newValue);
        onSelectedUser(newValue);
    };

    const getSelectedUserArray = (): User[] | string[] | null => {
        if (!selectedUser) return [];
        if (Array.isArray(selectedUser)) return selectedUser;
        if (typeof selectedUser === 'string') {
            return [selectedUser];
        }
        return [selectedUser];
    };

    const userItemTemplate = (user: User) => {
        return <div>{user.nameWithSuffix}</div>;
    };

    return (
        <AutoComplete
            value={multiple ? getSelectedUserArray() : selectedUser}
            suggestions={userSuggestions}
            placeholder="Person..."
            delay={400}
            minLength={2}
            field="nameWithSuffix"
            onChange={({ originalEvent, value }) => onChangeUser(originalEvent, value)}
            className="users-selector"
            itemTemplate={userItemTemplate}
            multiple={multiple ? true : undefined}
            completeMethod={searchUsers}
        />
    );
};
