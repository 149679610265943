import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Interval } from 'luxon';
import { AvailabilityProvider, AvailabilitySettingsSetterDialog, Loader } from 'concert-ui-library';
import { AvailabilityCategory, AvailabilitySettingCategoryMap, AvailabilityType } from './slice';
import { selectSettingsUser } from './selector';
import { selectUser } from '../user/selector';
import { SchedulingDateFunctions } from '../../services/scheduling-date-fns';
import type { AvailabilitySettingsUpdateRequest } from './availability-functions';
import { isManagerOrSuperUser } from './availability-functions';
import { User } from '../user/slice';
import { WeekDayView } from './week-day-view';
import { PracticeSelector } from '../practices/practices-selector';
import {
    AvailabilitySettingByDate,
    AvailabilitySettingByDay,
    AvailabilitySettingTimeslot,
    useLazyGetUserAvailabilitySettingsQuery,
    useUpdateAvailabilitySettingsMutation,
} from '../../services/graphql/generated';
import { NotificationContext } from '../../notification-context';
import { UPDATE_AVAILABILITY_SETTINGS_ERROR } from './constants';

export interface AvailabilitySettingsDataTableRow {
    dateOfAvailability: string;
    dateOfAvailabilityShort: string;
    times: AvailabilitySettingTimeslot[];
}
export const OnsiteAvailabilty: FunctionComponent = () => {
    const dateKeyFormat = 'yyyy-MM-dd';
    const [getAvailabilitySettings, { data: availabilitySettings, isFetching: isAvailabilityLoading }] =
        useLazyGetUserAvailabilitySettingsQuery();
    const [
        updateAvailability,
        { isLoading: updateInProgress, isSuccess: availabilityUpdated, error: availabilityUpdateError },
    ] = useUpdateAvailabilitySettingsMutation();

    const [selectedAvailSettingsToEdit, setSelectedAvailSettingsToEdit] =
        useState<null | AvailabilitySettingCategoryMap>(null);

    const [isAvailabilityUpdateCompleted, setIsAvailabilityUpdateCompleted] = useState(false);
    const notificationContext = useContext(NotificationContext);
    const transformAvailabilitySettings = (
        settings: AvailabilitySettingByDate[] | null | undefined,
    ): AvailabilitySettingsDataTableRow[] => {
        if (settings === null || settings === undefined) {
            return [];
        }
        return settings
            .filter((value: AvailabilitySettingByDate) => {
                return DateTime.fromFormat(value.date, dateKeyFormat).startOf('day') >= DateTime.now().startOf('day');
            })
            .map((value: AvailabilitySettingByDate) => {
                return {
                    dateOfAvailability: DateTime.fromFormat(value.date, dateKeyFormat).toISODate(),
                    dateOfAvailabilityShort: value.date,
                    times: value.timeslots,
                };
            });
    };

    const onSelectedDayToEdit = (dayToEdit: AvailabilitySettingByDay) => {
        setSelectedAvailSettingsToEdit({
            availabilityType: AvailabilityType.ByDay,
            value: dayToEdit,
        });
        setdisplayDialog(true);
    };

    const onSelectedDateToEdit = (dateToEdit: Date) => {
        const luxonDateToEdit = DateTime.fromJSDate(dateToEdit);
        const dateKey = luxonDateToEdit.toFormat(dateKeyFormat);
        const siteAvailSettingsApi = availabilitySettings?.getAvailabilitySettings?.site?.byDate?.find(
            (p) => p?.date === dateKey,
        );
        setSelectedAvailSettingsToEdit(
            siteAvailSettingsApi
                ? {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: luxonDateToEdit.toISODate(), timeslots: siteAvailSettingsApi.timeslots },
                  }
                : {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: luxonDateToEdit.toISODate(), timeslots: [] },
                  },
        );
    };
    const onHide = (dataChanged: boolean) => {
        setdisplayDialog(false);
        setIsAvailabilityUpdateCompleted(false);
        if (dataChanged) {
            getAvailabilitySettings({
                request: { userIds: [settingsUser.id] as string[] },
            });
        }
    };

    const user = useSelector(selectUser);
    const settingsUser = useSelector(selectSettingsUser);

    const shouldEditBeDisabled = (loggedInUser: User | null, userToBeViewed: User) => {
        return loggedInUser?.id !== userToBeViewed.id && !isManagerOrSuperUser(loggedInUser, userToBeViewed);
    };

    const [isEditDisabled, setIsEditDisabled] = useState(shouldEditBeDisabled(user, settingsUser));

    const validateIntervalIsWithinAvailabilityBlocks = (interval: Interval): { isValid: boolean; message: string } => {
        let dayOfWeekString: string | null = null;
        const validReturn = {
            isValid: true,
            message: '',
        };
        const invalidReturn = {
            isValid: false,
            message: 'Onsite availability is outside of general availability times',
        };

        if (!selectedAvailSettingsToEdit) {
            return validReturn;
        }

        if (selectedAvailSettingsToEdit?.availabilityType === AvailabilityType.ByDate) {
            dayOfWeekString = SchedulingDateFunctions.getLuxonDateTimeFromDateString(
                (selectedAvailSettingsToEdit.value as AvailabilitySettingByDate).date,
                'yyyy-MM-dd',
            ).weekdayLong;
        } else {
            dayOfWeekString = (selectedAvailSettingsToEdit?.value as AvailabilitySettingByDay).day;
        }

        // get general availability blocks for that day
        const settingsForThatDayOfWeek = availabilitySettings
            ? availabilitySettings.getAvailabilitySettings?.general?.byDay?.find(
                  (slot) => slot?.day === dayOfWeekString,
              )?.timeslots
            : null;

        if (!settingsForThatDayOfWeek) {
            // if there are not settings for that day of week means no general availability
            return invalidReturn;
        }

        const isIntervalEngulfedInAAvailabilityBlock = (settingsForThatDayOfWeek as AvailabilitySettingTimeslot[]).some(
            (setting) => {
                const settingInterval = SchedulingDateFunctions.getIntervalFromTimesMilitaryFormat(
                    setting.startTime,
                    setting.endTime,
                );
                return settingInterval.engulfs(interval);
            },
        );
        if (isIntervalEngulfedInAAvailabilityBlock) {
            return validReturn;
        }
        return invalidReturn;
    };

    useEffect(() => {
        setIsEditDisabled(shouldEditBeDisabled(user, settingsUser));
        if (availabilitySettings) return;
        getAvailabilitySettings({
            request: { userIds: [settingsUser.id] as string[] },
        });
    }, [user, settingsUser, availabilitySettings]);

    useEffect(() => {
        if (availabilityUpdateError) {
            notificationContext?.showError(UPDATE_AVAILABILITY_SETTINGS_ERROR);
        }
    }, [availabilityUpdateError]);

    useEffect(() => {
        setIsAvailabilityUpdateCompleted(availabilityUpdated);
    }, [availabilityUpdated]);

    const [displayDialog, setdisplayDialog] = useState(false);
    return (
        <div>
            {isAvailabilityLoading ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <WeekDayView
                            settings={availabilitySettings?.getAvailabilitySettings?.site?.byDay ?? null}
                            availCategory={AvailabilityCategory.Site}
                            isEditDisabled={isEditDisabled}
                            onClickEdit={onSelectedDayToEdit}
                        />
                    </div>
                    <div className="card">
                        {displayDialog === true && selectedAvailSettingsToEdit ? (
                            <AvailabilityProvider>
                                <AvailabilitySettingsSetterDialog
                                    onClose={onHide}
                                    availabilitySettings={selectedAvailSettingsToEdit?.value.timeslots}
                                    date={
                                        selectedAvailSettingsToEdit?.availabilityType === AvailabilityType.ByDate
                                            ? (selectedAvailSettingsToEdit?.value as AvailabilitySettingByDate).date
                                            : undefined
                                    }
                                    dayName={
                                        selectedAvailSettingsToEdit?.availabilityType === AvailabilityType.ByDay
                                            ? (selectedAvailSettingsToEdit?.value as AvailabilitySettingByDay).day
                                            : undefined
                                    }
                                    onDateSelected={onSelectedDateToEdit}
                                    availabilityType={selectedAvailSettingsToEdit?.availabilityType || ''}
                                    category={AvailabilityCategory.Site}
                                    validateIntervalFn={validateIntervalIsWithinAvailabilityBlocks}
                                    PracticeSelector={PracticeSelector}
                                    userId={settingsUser.id}
                                    onSaving={(settingsToSave: AvailabilitySettingsUpdateRequest) =>
                                        updateAvailability({
                                            request: {
                                                settingsToBeAdded: [...settingsToSave.settingsToBeAdded],
                                                settingsToBeDeleted: [...settingsToSave.settingsToBeDeleted],
                                                settingsToBeModified: [...settingsToSave.settingsToBeModified],
                                            },
                                        })
                                    }
                                    defaultStartTime="8:00 AM"
                                    defaultEndTime="4:00 PM"
                                    defaultStepMinute={30}
                                    isLoading={updateInProgress}
                                    isSaved={isAvailabilityUpdateCompleted}
                                />
                            </AvailabilityProvider>
                        ) : (
                            <div />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
