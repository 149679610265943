import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';
import { User } from '../user/slice';

export const initialState: { data: User[]; error: string | null; status: ApiCallStatus } = {
    data: [],
    error: null,
    status: ApiCallStatus.idle,
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers(state, action) {
            state.status = ApiCallStatus.loading;
            return state;
        },
        clearUsers(state) {
            state.data = [];
        },
        setUsersSuccess(state, action) {
            state.status = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        setUsersFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { getUsers, setUsersSuccess, setUsersFailure, clearUsers } = usersSlice.actions;

export const { reducer } = usersSlice;
