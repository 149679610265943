import { TreeNode } from 'primereact/treenode';
import { UserPracticeNode } from './slice';

export function flattenToSelections(nodeMap: any, userPractice: UserPracticeNode): any {
    nodeMap[userPractice.id] = { checked: userPractice.acceptingNewPatients };
    if (userPractice.children.length > 0) {
        userPractice.children.forEach((child: UserPracticeNode) => flattenToSelections(nodeMap, child));
    }
    return nodeMap;
}

export function toTreeFormat(userPractice: UserPracticeNode): TreeNode {
    return {
        label: userPractice.accountName,
        key: userPractice.id,
        data: userPractice,
        children: userPractice.children.map(toTreeFormat),
    };
}
