import { createSlice } from '@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../enums/localStorageKeys';

export const initialState = {
    groupByUser: localStorage.getItem(LocalStorageKeys.groupByUser) === 'true',
};

const schedulerViewSettingsSlice = createSlice({
    name: 'schedulerViewSettings',
    initialState,
    reducers: {
        swapGroupByUserSetting(state) {
            localStorage.setItem(LocalStorageKeys.groupByUser, String(!state.groupByUser));
            state.groupByUser = !state.groupByUser;
        },
    },
});

export const { swapGroupByUserSetting } = schedulerViewSettingsSlice.actions;

export const { reducer } = schedulerViewSettingsSlice;
