import { DateTime } from 'luxon';
import type { User } from '../user/slice';
import type { AvailabilitySettingByDate, AvailabilitySettingTimeslotInput } from '../../services/graphql/generated';
import type { AvailabilitySettingsDataTableRow } from './onsite-availability';

export interface SettingsRow extends AvailabilitySettingTimeslotInput {
    isDeleted?: boolean;
    isInvalid?: boolean;
    isNew?: boolean;
}
export interface AvailabilitySettingsUpdateRequest {
    settingsToBeAdded: SettingsRow[];
    settingsToBeDeleted: SettingsRow[];
    settingsToBeModified: SettingsRow[];
}

export const isManagerOrSuperUser = (loggedInUser: User | null, userBeingViewed: User): boolean => {
    return (
        loggedInUser?.hasConcertSettingsManagerAccess ||
        !!loggedInUser?.isSysAdmin ||
        loggedInUser?.id === userBeingViewed.managerId
    );
};

export const transformAvailabilitySettings = (
    settings: AvailabilitySettingByDate[] | null | undefined,
    dateKeyFormat: string,
    dataRenderFormat: string,
): AvailabilitySettingsDataTableRow[] => {
    if (settings === null || settings === undefined) {
        return [];
    }
    return settings
        .filter((value: AvailabilitySettingByDate) => {
            return DateTime.fromFormat(value.date, dateKeyFormat).startOf('day') >= DateTime.now().startOf('day');
        })
        .map((value: AvailabilitySettingByDate) => {
            return {
                dateOfAvailability: DateTime.fromFormat(value.date, dateKeyFormat).toISODate(),
                dateOfAvailabilityShort: DateTime.fromFormat(value.date, dateKeyFormat).toFormat(dataRenderFormat),
                times: value.timeslots,
            };
        });
};
