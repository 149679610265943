import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getSettingsUser, getSettingsUserSuccess, AvailabilityOwnerType } from './slice';
import { ErrorConstants } from '../error/constants';
import type { Action, ErrorMessage } from '../../common-types';
import type { AvailabilitySettingsUpdateRequest } from './availability-functions';

export type GetSettingsAction = {
    id: string;
    idType: AvailabilityOwnerType;
};
export type SaveSettingsAction = {
    data: AvailabilitySettingsUpdateRequest;
    idType: AvailabilityOwnerType;
};

function* getSettingsUserFromApi(action: Action) {
    try {
        const { payload } = action;
        const { data } = yield axios.get(`${axios.defaults.baseURL}/users/${payload}`);
        yield put(getSettingsUserSuccess({ ...data }));
    } catch (error: unknown) {
        const errorMessage = error as ErrorMessage;
        errorMessage.customMessage = null;
        const apiError = {
            message: errorMessage.message,
            customMessage: `Error getting user settings. ${ErrorConstants.UserCheckMessage} `,
        };
        yield put(getSettingsUserSuccess({ error: apiError }));
    }
}

export function* watchGetSettingsUser(): Generator {
    yield takeEvery(getSettingsUser, getSettingsUserFromApi);
}
