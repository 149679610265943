import { createSlice } from '@reduxjs/toolkit';
import { Episode } from '../../episodes/slice';
import { User } from '../../user/slice';
import { AvailabilityWhoFilterType } from '../scheduler-filter/slice';
import { UtilizationForUser } from '../user-availability-info/utiization-calc';
import { Timeslot } from '../../../services/graphql/generated';

export interface AvailabilitySlotDetails {
    providers: AvailabilityProviderDetails[];
}

export interface ProviderCapacity {
    timeslotsAvailableInNearFuture: number;
}
export interface AvailabilityProviderDetails {
    provider: User;
    isOnSitePractice: boolean;
    acceptingNewPatients: boolean;
    specialties: string[];
    notAcceptingType: string;
    engagementTypes: string[];
    utilization?: UtilizationForUser;
    capacity?: ProviderCapacity;
}
export interface ExtendedTimeslot extends Omit<Timeslot, 'providerAvailability'> {
    providerAvailability: AvailabilityProviderDetails[];
}

export interface AvailabilityRequest {
    startDateTime: string;
    endDateTime: string;
    providerAvailability: AvailabilityProviderDetails[];
    whoFilterType: AvailabilityWhoFilterType;
    practiceId: string | null;
    languageKey: string | null;
    slotDuration: number;
    tzTimeZone: string;
    episode: Episode;
}
export type AvailabilityProviderDataMap = {
    [key: string]: AvailabilityProviderDetails;
};

export interface AvailabilityRefineOption {
    name: string;
    value: string;
}

export interface AvailabilityInitialState {
    data: AvailabilityProviderDataMap;
    loading: boolean;
    error: boolean;
    refineAvailabilitySelectedOptions: string[];
    refineOptions: AvailabilityRefineOption[];
    selectedSlot: AvailabilitySlotDetails;
}

export const initialState: AvailabilityInitialState = {
    data: {},
    loading: false,
    error: false,
    refineAvailabilitySelectedOptions: ['EngagementType'],
    refineOptions: [],
    selectedSlot: { providers: [] },
};

const availabilitySlice = createSlice({
    name: 'availability',
    initialState,
    reducers: {
        getAvailability(state, action) {
            state.data = {} as AvailabilityProviderDataMap;
            state.loading = true;
            state.error = false;
        },
        setAvailabilitySuccess(state, action) {
            state.data = action.payload.data;
            state.loading = false;
        },
        setAvailabilityFailure(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setRefineAvailabilitySelectedOptions(state, action) {
            state.refineAvailabilitySelectedOptions = action.payload;
            return state;
        },
        setRefineOptions(state, action) {
            state.refineOptions = action.payload;
            return state;
        },
        setSelectedSlot(state, action) {
            state.selectedSlot = action.payload;
            return state;
        },
        clearRefineSelectionOptions(state) {
            state.refineAvailabilitySelectedOptions = initialState.refineAvailabilitySelectedOptions;
            return state;
        },
    },
});

export const {
    getAvailability,
    setAvailabilitySuccess,
    setAvailabilityFailure,
    setRefineAvailabilitySelectedOptions,
    setRefineOptions,
    setSelectedSlot,
    clearRefineSelectionOptions,
} = availabilitySlice.actions;

export const { reducer } = availabilitySlice;
