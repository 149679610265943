import { Duration, DateTime as luxonDateTime } from 'luxon';
import { SchedulingRequest } from '../slice';

export const buildSchedulingRequest = (
    selectedDate: Date,
    slotDuration: number | Duration,
    timezone: string,
    selectedSlot: string | Duration,
): SchedulingRequest => {
    const luxonSelectedDateCalendar = luxonDateTime.fromJSDate(selectedDate);
    const luxonSelectedDate = luxonSelectedDateCalendar
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .setZone(timezone, { keepLocalTime: true });
    const duration = typeof slotDuration === 'number' ? Duration.fromObject({ minutes: slotDuration }) : slotDuration;
    const timeSlotStartTime = typeof selectedSlot === 'string' ? Duration.fromISOTime(selectedSlot) : selectedSlot;
    const luxonStartDateTime = luxonSelectedDate.set({
        hour: timeSlotStartTime.hours,
        minute: timeSlotStartTime.minutes,
        second: 0,
        millisecond: 0,
    });
    return {
        selectedDate,
        selectedLuxonDate: luxonSelectedDate,
        startDateTime: luxonStartDateTime,
        endDateTime: luxonStartDateTime.plus(duration),
        duration,
        timeSlotStartTime,
        timeSlotEndTime: timeSlotStartTime.plus(duration),
        timezone,
        selectedTimeSlot: timeSlotStartTime.toISOTime({ suppressMilliseconds: true }),
    };
};
