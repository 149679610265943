import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../../services/api-call-status';
import { noCommunicationErrorMessages } from '../constants';

export interface CalendarEventRequest {
    startDateTime: string;
    endDateTime: string;
    subject: string;
    whoId: string;
    episodeId: string;
    notes: string;
    eventType: string;
    episodeAssignedToUserId: string | null;
}

export const initialState = {
    status: ApiCallStatus.idle,
    error: '',
};

const calendarEventSlice = createSlice({
    name: 'calendarEvent',
    initialState,
    reducers: {
        createCalendarEvent(state, action) {
            state.status = ApiCallStatus.loading;
        },
        setCalendarEventIdle(state) {
            state.status = ApiCallStatus.idle;
        },
        setCalendarEventSuccess(state, action) {
            state.error = '';
            const successStatus = 'SUCCESS';
            const notificationWasSent =
                action.payload.data.confirmationEmailStatus === successStatus ||
                action.payload.data.confirmationSmsStatus === successStatus;
            if (!notificationWasSent) {
                state.error = noCommunicationErrorMessages.noNotificationWillBeSent;
            }
            state.status = ApiCallStatus.succeeded;
        },
        setCalendarEventFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { createCalendarEvent, setCalendarEventIdle, setCalendarEventSuccess, setCalendarEventFailure } =
    calendarEventSlice.actions;

export const { reducer } = calendarEventSlice;
