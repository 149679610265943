import axios from 'axios';
import { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'concert-ui-library';
import { jwtDecode } from 'jwt-decode';
import { changeUser, User } from '../user/slice';
import ErrorComponent from '../../components/error';
import { changeAuthState } from './slice';
import { getInitials } from '../../services/get-initials';
import { useLazyGetAuthenticationTokenQuery } from '../../services/graphql/generated';
import { selectLoginRedirectLocation } from './selector';
import { ROUTE_PARAMS } from '../../constants';

interface ChToken {
    sub: string;
    given_name: string;
    family_name: string;
    name: string;
    timezone: string;
    'ch.timezone': string;
    'ch.name': string;
    'ch.sfCustomPermission': string;
}

const OauthCallback: FunctionComponent = () => {
    const loginRedirectLocation = useSelector(selectLoginRedirectLocation);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [redirectUrl, setRedirectUrl] = useState({
        pathname: loginRedirectLocation?.pathname ?? '/',
        search: loginRedirectLocation?.search ?? '',
    });

    const [getAuthenticationTokenQuery] = useLazyGetAuthenticationTokenQuery();

    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        const getAuthToken = async () => {
            try {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const code = urlSearchParams.get(ROUTE_PARAMS.SALESFORCE_AUTH_CODE);
                const state = urlSearchParams.get(ROUTE_PARAMS.SALESFORCE_AUTH_STATE);
                if (code === null || code === '' || code === undefined) {
                    history.push('/login');
                    return;
                }
                if (state !== null) {
                    const indexOfQueryString = state.indexOf('?');
                    const redirectObject = {
                        pathname: '/',
                        search: '',
                    };
                    if (indexOfQueryString !== -1) {
                        redirectObject.pathname = state.substring(0, indexOfQueryString);
                        redirectObject.search = state.substring(indexOfQueryString, state.length);
                    } else {
                        redirectObject.pathname = state;
                    }
                    setRedirectUrl(redirectObject);
                }

                dispatch(changeAuthState({ auth: false, token: `code-${code}` }));

                const response = await getAuthenticationTokenQuery();
                if (response.isError) {
                    throw new Error(response.error);
                }
                const authenticationToken = response.data?.getAuthenticationToken ?? '';
                const tokenObject = jwtDecode(authenticationToken) as ChToken;
                sessionStorage.setItem('chToken', authenticationToken);
                dispatch(changeAuthState({ auth: true, token: authenticationToken }));
                const userData = await axios.get(`${axios.defaults.baseURL}/users/${tokenObject.sub}`);
                const user: User = {
                    id: tokenObject.sub,
                    first_name: tokenObject.given_name,
                    last_name: tokenObject.family_name,
                    initials: getInitials(tokenObject.given_name, tokenObject.family_name),
                    timezone: tokenObject['ch.timezone'],
                    name: tokenObject['ch.name'],
                    sfCustomPermissions: tokenObject['ch.sfCustomPermission'],
                    ...userData.data,
                };
                dispatch(changeUser(user));

                setIsAuthenticated(true);
                setIsAuthenticating(false);
            } catch (exception) {
                setIsAuthenticating(false);
                setIsAuthenticated(false);
                dispatch(changeAuthState(false));
            }
        };
        getAuthToken();
    }, []);
    if (isAuthenticating) return <Loader />;

    return (
        <div>
            {isAuthenticated ? (
                <Redirect to={{ pathname: redirectUrl.pathname, search: redirectUrl.search }} />
            ) : (
                <ErrorComponent />
            )}
        </div>
    );
};

export default OauthCallback;
