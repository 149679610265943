import { put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosError } from 'axios';
import { createCalendarEvent, setCalendarEventFailure, setCalendarEventSuccess } from './calendar-event-slice';
import { ErrorConstants } from '../../error/constants';
import { addError } from '../../error/slice';
import { AVAILABILITY_ERROR_MESSAGES } from '../availability/constants';

function* createCalendarEventApi(action: any) {
    try {
        const { payload } = action;
        const { data } = yield axios.post(`${axios.defaults.baseURL}/calendar-event`, payload);
        // if request partially succeeded
        if (data.status === 1) {
            yield put(addError({ customMessage: data.error }));
        }
        yield put(setCalendarEventSuccess(data));
    } catch (error) {
        let errorMessage = '';
        let statusCode;
        if (error instanceof AxiosError) {
            errorMessage = error.message;
            statusCode = error.response?.status;
        } else if (error instanceof Error) {
            errorMessage = error.message;
        }
        const apiError = {
            message: errorMessage,
            customMessage: `Error saving appointment. ${ErrorConstants.UserCheckMessage}`,
            statusCode,
        };

        if (statusCode === 409) {
            apiError.customMessage = AVAILABILITY_ERROR_MESSAGES.APPOINTMENT_NO_LONGER_AVAILABLE;
        }
        yield put(setCalendarEventFailure({ error: apiError }));
    }
}

export default function* watchCreateCalendarEvent(): Generator {
    yield takeEvery(createCalendarEvent, createCalendarEventApi);
}
