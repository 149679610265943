import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../../services/api-call-status';
import { Episode } from '../../episodes/slice';
import { Practice } from '../../practices/slice';
import { User } from '../../user/slice';

export enum AvailabiltyWhatFilterType {
    'Episode',
    'Patient',
    'Contact',
}
export enum AvailabilityWhoFilterType {
    'User',
    'Team',
}

export interface AvailabilityWhatFilter {
    value: unknown | null;
    type: never | AvailabiltyWhatFilterType;
}

export interface AvailabilityWhoFilter {
    value: User[] | AvailabilityTeamFilter | null;
    type: never | AvailabilityWhoFilterType;
}

export interface AvailabilityTeamFilter {
    practice: Practice | null;
    language: string | null;
}

export interface AvailabilityFilter {
    practice: Practice | null;
    episode: Episode | null;
    user: User[] | null;
    language: string | null;
    who: AvailabilityWhoFilterType;
}

export const initialState = {
    filter: {
        practice: null as Practice | null,
        episode: null as Episode | null,
        user: null as User[] | null,
        language: null as string | null,
        who: AvailabilityWhoFilterType.User as AvailabilityWhoFilterType,
    },
    initialParams: {
        data: {
            practiceId: null as string | null,
            episodeId: null as string | null,
            userId: null as string | null,
        },
        status: ApiCallStatus.idle,
        error: null as string | null,
    },
    filterSelectionComplete: false,
};

const schedulerFilterSlice = createSlice({
    name: 'schedulerFilter',
    initialState,
    reducers: {
        setAvailabilityFilter(state, action) {
            state.filter = action.payload;
        },
        resetAvailabilityFilter(state) {
            state.filter = initialState.filter;
        },
        hydrateFilterParams(state, action) {
            state.initialParams.data = action.payload;
            state.initialParams.status = ApiCallStatus.loading;
        },
        hydrateFilterParamsComplete(state, action) {
            state.initialParams.status = ApiCallStatus.succeeded;
        },
        changeFilterSelection(state, action) {
            state.filterSelectionComplete = action.payload;
        },
    },
});

export const {
    setAvailabilityFilter,
    resetAvailabilityFilter,
    hydrateFilterParams,
    hydrateFilterParamsComplete,
    changeFilterSelection,
} = schedulerFilterSlice.actions;

export const { reducer } = schedulerFilterSlice;
