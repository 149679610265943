import { FunctionComponent, useContext, useEffect, useState } from 'react';
import './settings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, AvailabilitySettingsSetterDialog, AvailabilityProvider } from 'concert-ui-library';
import { AvailabilityCategory } from './slice';
import { selectSettingsUser } from './selector';
import { selectUser } from '../user/selector';
import { AvailabilitySettingsUpdateRequest, isManagerOrSuperUser } from './availability-functions';
import { User } from '../user/slice';
import { WeekDayView } from './week-day-view';
import { selectUserConcertSettings } from './other/selector';
import { getUserConcertSettings } from './other/slice';
import {
    AvailabilitySettingByDay,
    useLazyGetUserAvailabilitySettingsQuery,
    useUpdateAvailabilitySettingsMutation,
} from '../../services/graphql/generated';
import { NotificationContext } from '../../notification-context';
import { UPDATE_AVAILABILITY_SETTINGS_ERROR } from './constants';
import { ExtendedAvailability } from './extended-availability/extended-availability';

export const GeneralAvailability: FunctionComponent = () => {
    const dateKeyFormat = 'yyyy-MM-dd';
    const dataRenderFormat = 'MM/dd/yyyy';
    const dispatch = useDispatch();
    const notificationContext = useContext(NotificationContext);
    const generalSettings = useSelector(selectUserConcertSettings);
    const [selectedAvailSettingsToEdit, setSelectedAvailSettingsToEdit] = useState<null | AvailabilitySettingByDay>(
        null,
    );

    const currentUser = useSelector(selectUser);
    const settingsUser = useSelector(selectSettingsUser);
    const [getAvailabilitySettings, { data: settings, isFetching: isAvailabilityLoading }] =
        useLazyGetUserAvailabilitySettingsQuery();
    const [
        updateAvailability,
        { isLoading: updateInProgress, isSuccess: availabilityUpdated, error: availabilityUpdateError },
    ] = useUpdateAvailabilitySettingsMutation();
    const getIsEditDisabled = (loggedInUser: User | null, userToBeViewed: User) => {
        return loggedInUser?.id !== userToBeViewed.id && !isManagerOrSuperUser(loggedInUser, userToBeViewed);
    };

    const [isEditDisabled, setIsEditDisabled] = useState(getIsEditDisabled(currentUser, settingsUser));
    const [isAvailabilityUpdateCompleted, setIsAvailabilityUpdateCompleted] = useState(false);
    const isGeneralAvailabilityEnabled =
        currentUser && (currentUser.hasConcertSettingsManagerAccess || currentUser.isSysAdmin);

    const getAvailabilitySettingsByFilter = () => {
        getAvailabilitySettings({
            request: { userIds: [settingsUser.id] as string[] },
        });
    };
    useEffect(() => {
        setIsEditDisabled(getIsEditDisabled(currentUser, settingsUser));
        getAvailabilitySettingsByFilter();
        dispatch(getUserConcertSettings(settingsUser.id));
    }, [currentUser, settingsUser]);

    useEffect(() => {
        if (availabilityUpdateError) {
            notificationContext?.showError(UPDATE_AVAILABILITY_SETTINGS_ERROR);
        }
    }, [availabilityUpdateError]);

    useEffect(() => {
        setIsAvailabilityUpdateCompleted(availabilityUpdated);
    }, [availabilityUpdated]);

    const showAvailabilitySetter = (entry: AvailabilitySettingByDay) => {
        setSelectedAvailSettingsToEdit(entry);
    };

    const onDialogClose = (dataChanged: boolean) => {
        setSelectedAvailSettingsToEdit(null);
        setIsAvailabilityUpdateCompleted(false);
        if (dataChanged) {
            getAvailabilitySettingsByFilter();
        }
    };
    const saveSettingsAction = (settingsToSave: AvailabilitySettingsUpdateRequest, shouldRefreshData: boolean) => {
        updateAvailability({
            request: {
                settingsToBeAdded: [...settingsToSave.settingsToBeAdded],
                settingsToBeDeleted: [...settingsToSave.settingsToBeDeleted],
                settingsToBeModified: [...settingsToSave.settingsToBeModified],
            },
        }).then(() => {
            onDialogClose(shouldRefreshData);
        });
    };

    return (
        <div>
            {isAvailabilityLoading ? (
                <Loader />
            ) : (
                <div>
                    <WeekDayView
                        settings={settings?.getAvailabilitySettings?.general?.byDay ?? null}
                        availCategory={AvailabilityCategory.General}
                        isEditDisabled={!isGeneralAvailabilityEnabled}
                        onClickEdit={showAvailabilitySetter}
                        userWeeklyClinicalHours={generalSettings?.weeklyClinicalHours}
                    />
                    {selectedAvailSettingsToEdit !== null ? (
                        <AvailabilityProvider>
                            <AvailabilitySettingsSetterDialog
                                onClose={onDialogClose}
                                userId={settingsUser.id}
                                availabilitySettings={selectedAvailSettingsToEdit.timeslots}
                                dayName={selectedAvailSettingsToEdit?.day}
                                availabilityType="ByDay"
                                onSaving={(request: AvailabilitySettingsUpdateRequest) =>
                                    saveSettingsAction(request, true)
                                }
                                defaultStartTime="8:00 AM"
                                defaultEndTime="4:00 PM"
                                isLoading={updateInProgress}
                                isSaved={isAvailabilityUpdateCompleted}
                                defaultStepMinute={30}
                            />
                        </AvailabilityProvider>
                    ) : (
                        <div />
                    )}
                    <AvailabilityProvider>
                        <ExtendedAvailability
                            userId={settingsUser.id}
                            isReadonly={isEditDisabled}
                            availabilitySettings={settings?.getAvailabilitySettings || null}
                            dateKeyFormat={dateKeyFormat}
                            isUpdating={updateInProgress}
                            isUpdated={isAvailabilityUpdateCompleted}
                            onSaving={(request: AvailabilitySettingsUpdateRequest) =>
                                saveSettingsAction(request, false)
                            }
                            dateRenderFormat={dataRenderFormat}
                        />
                    </AvailabilityProvider>
                </div>
            )}
        </div>
    );
};
