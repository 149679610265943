import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';

export type EngagementTypes = {
    label: string;
    value: string;
};

export const initialState = {
    data: [] as any,
    error: null,
    status: ApiCallStatus.idle,
};

const engagementTypesSlice = createSlice({
    name: 'engagement-types',
    initialState,
    reducers: {
        getEngagementTypes(state) {
            state.status = ApiCallStatus.loading;
            return state;
        },
        getEngagementTypesSuccess(state, action) {
            state.status = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        getEngagementTypesFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { getEngagementTypes, getEngagementTypesFailure, getEngagementTypesSuccess } =
    engagementTypesSlice.actions;

export const { reducer } = engagementTypesSlice;
