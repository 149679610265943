import { AvailabilityViewer } from 'concert-ui-library';
import { FC, useContext, useEffect, useState } from 'react';
import { AvailabilityType } from '../slice';
import {
    AvailabilitySettingsCategoryGroup,
    useUpdateAvailabilitySettingsMutation,
} from '../../../services/graphql/generated';
import { AvailabilitySettingsUpdateRequest } from '../availability-functions';
import { NotificationContext } from '../../../notification-context';
import { UPDATE_AVAILABILITY_SETTINGS_ERROR } from '../constants';

export type PreferredDaysAndTimesProps = {
    patientId: string;
    patientAvailability: AvailabilitySettingsCategoryGroup | null;
    isAvailabilityLoading: boolean;
    refreshPatientSettings: () => void;
};

export const PreferredDaysAndTimes: FC<PreferredDaysAndTimesProps> = ({
    patientAvailability,
    patientId,
    isAvailabilityLoading,
    refreshPatientSettings,
}) => {
    const [
        updateAvailability,
        { isLoading: updateInProgress, isSuccess: availabilityUpdated, error: availabilityUpdateError },
    ] = useUpdateAvailabilitySettingsMutation();
    const [isAvailabilityUpdateCompleted, setIsAvailabilityUpdateCompleted] = useState(false);
    const notificationContext = useContext(NotificationContext);

    const onPattientSettingsHide = (dataChanged: boolean) => {
        setIsAvailabilityUpdateCompleted(false);
        if (dataChanged) {
            refreshPatientSettings();
        }
    };

    useEffect(() => {
        setIsAvailabilityUpdateCompleted(availabilityUpdated);
    }, [availabilityUpdated]);

    useEffect(() => {
        if (availabilityUpdateError) {
            notificationContext?.showError(UPDATE_AVAILABILITY_SETTINGS_ERROR);
        }
    }, [availabilityUpdateError]);

    const AvailabilitySettingsSetterProps = {
        availabilityType: AvailabilityType.ByDay,
        patientId,
        onSaving: (settingsToSave: AvailabilitySettingsUpdateRequest) =>
            updateAvailability({
                request: {
                    settingsToBeAdded: [
                        ...settingsToSave.settingsToBeAdded.map((row) => ({
                            ...row,
                            id: undefined,
                        })),
                    ],
                    settingsToBeDeleted: [...settingsToSave.settingsToBeDeleted],
                    settingsToBeModified: [...settingsToSave.settingsToBeModified],
                },
            }),
        isLoading: updateInProgress,
        isSaved: isAvailabilityUpdateCompleted,
        defaultStartTime: '12:00 PM',
        defaultEndTime: '12:00 PM',
        defaultStepMinute: 15,
    };
    return patientId && patientAvailability ? (
        <div>
            <h3>Preferred Days & Times</h3>
            <AvailabilityViewer
                availability={{
                    byDate: patientAvailability?.byDate as [],
                    byDay: patientAvailability?.byDay as [],
                }}
                refreshData={onPattientSettingsHide}
                isAvailabilityLoading={isAvailabilityLoading}
                availabilitySettingsSetterProps={AvailabilitySettingsSetterProps}
            />
        </div>
    ) : null;
};
