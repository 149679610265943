import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../../services/api-call-status';

export interface UserConcertSetting {
    acceptingNewPatients: boolean;
    engagementTypes: string[];
    targetCaseLoad: number | undefined;
    activeEpisodes: number | undefined;
    caseloadAllocation: number | undefined;
    userId?: string;
    weeklyClinicalHours: number | null;
}

export interface UserPracticeNode {
    id: string;
    acceptingNewPatients: boolean;
    accountState?: string;
    accountName: string;
    accountId: string;
    children: UserPracticeNode[];
}

export interface GeneralSettings {
    userConcertSetting: UserConcertSetting;
    userPractices: UserPracticeNode[];
}

export const initialState = {
    data: {} as GeneralSettings,
    error: null,
    getStatus: ApiCallStatus.idle,
    userPracticeSaveStatus: ApiCallStatus.idle,
    userPracticeUpdateStatus: ApiCallStatus.idle,
    selections: {},
    saveEngagementTypesStatus: ApiCallStatus.idle,
    saveAcceptingNewPatientsStatus: ApiCallStatus.idle,
};
export enum UserPracticesSavingOperation {
    insert = 'insert',
    update = 'update',
}
const concertSettingsSlice = createSlice({
    name: 'concertSettings',
    initialState,
    reducers: {
        getUserConcertSettings(state, action) {
            state.getStatus = ApiCallStatus.loading;
            return state;
        },
        getUserConcertSettingsSuccess(state, action) {
            state.getStatus = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        getUserConcertSettingsFailure(state, action) {
            state.getStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
        saveEngagementTypes(state, action) {
            state.saveEngagementTypesStatus = ApiCallStatus.loading;
            return state;
        },
        saveEngagementTypesSuccess(state, action) {
            state.saveEngagementTypesStatus = ApiCallStatus.succeeded;
            state.data = {
                ...state.data,
                userConcertSetting: {
                    ...state.data.userConcertSetting,
                    ...action.payload,
                },
            };
        },
        saveEngagementTypesFailure(state, action) {
            state.saveEngagementTypesStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
            return state;
        },
        resetSaveEngagementTypesStatus(state) {
            state.saveEngagementTypesStatus = ApiCallStatus.idle;
            return state;
        },
        saveAcceptingNewPatients(state, action) {
            state.saveAcceptingNewPatientsStatus = ApiCallStatus.loading;
            return state;
        },
        resetAcceptingNewPatientsSaveStatus(state) {
            state.saveAcceptingNewPatientsStatus = ApiCallStatus.idle;
        },
        saveAcceptingNewPatientsSuccess(state, action) {
            state.saveAcceptingNewPatientsStatus = ApiCallStatus.succeeded;
            state.data = {
                ...state.data,
                userConcertSetting: {
                    ...state.data.userConcertSetting,
                    ...action.payload,
                },
            };
        },
        saveAcceptingNewPatientsFailure(state, action) {
            state.saveAcceptingNewPatientsStatus = ApiCallStatus.failed;
            state.error = action.payload.error;
            return state;
        },
        saveUserPractices(state, action) {
            if (action.payload.type === UserPracticesSavingOperation.insert) {
                state.userPracticeSaveStatus = ApiCallStatus.loading;
            } else {
                state.userPracticeUpdateStatus = ApiCallStatus.loading;
            }

            return state;
        },
        saveUserPracticesSuccess(state, action) {
            if (action.payload.type === UserPracticesSavingOperation.insert) {
                state.userPracticeSaveStatus = ApiCallStatus.succeeded;
            } else {
                state.userPracticeUpdateStatus = ApiCallStatus.succeeded;
            }
            state.selections = action.payload.selections;
            return state;
        },

        resetUserPracticesSaveState(state) {
            state.userPracticeSaveStatus = ApiCallStatus.idle;
            state.userPracticeUpdateStatus = ApiCallStatus.idle;
            return state;
        },

        saveUserPracticesFailure(state, action) {
            if (action.payload.type === UserPracticesSavingOperation.insert) {
                state.userPracticeSaveStatus = ApiCallStatus.failed;
            } else {
                state.userPracticeUpdateStatus = ApiCallStatus.failed;
            }
            state.error = action.payload.error;
        },
    },
});

export const {
    getUserConcertSettingsSuccess,
    getUserConcertSettingsFailure,
    getUserConcertSettings,
    saveUserPractices,
    saveUserPracticesFailure,
    saveUserPracticesSuccess,
    resetUserPracticesSaveState,
    saveAcceptingNewPatientsSuccess,
    saveAcceptingNewPatientsFailure,
    saveAcceptingNewPatients,
    resetAcceptingNewPatientsSaveStatus,
    saveEngagementTypes,
    saveEngagementTypesSuccess,
    saveEngagementTypesFailure,
    resetSaveEngagementTypesStatus,
} = concertSettingsSlice.actions;

export const { reducer } = concertSettingsSlice;
