import { createContext } from 'react';

export interface SFConfig {
    loginUrl: string;
    redirectUri: string;
    clientId: string;
}

export interface GraphQLSettings {
    graphQLUrl: string;
    apiKey?: string;
}

export interface ECConfig {
    apiUrl: string;
    useMockApi: boolean;
    sfSettings: SFConfig;
    graphQLSettings: GraphQLSettings;
}

export const getGraphQLConfig = (): any => {
    const graphQLSettings = window?.config?.graphQLSettings as GraphQLSettings;
    return graphQLSettings;
};

export const ConfigContext: null | any = createContext(null);
