import { ApiCallStatus } from '../../services/api-call-status';
import type { RootState } from '../../store';
import { AvailabilityCategory } from './slice';

export const selectIsSettingsLoading = (state: RootState): boolean =>
    state.settings.getStatus === ApiCallStatus.loading;
export const selectSettings = (state: RootState) => state.settings.data;
export const selectSiteAvailSettings = (state: RootState) => state.settings.data[AvailabilityCategory.Site];
export const selectSaveSettingsStatus = (state: RootState): ApiCallStatus => state.settings.saveStatus;
export const selectSettingsUser = (state: RootState) => state.settings.settingsUser;
export const selectSettingsUserLoading = (state: RootState): boolean =>
    state.settings.getSettingsUserStatus === ApiCallStatus.loading ||
    state.settings.getSettingsUserStatus === ApiCallStatus.idle;
