import { AvailabilityCircleColor } from '../../../enums/availabilityCircleColor';
import { Episode } from '../../episodes/slice';
import { User } from '../../user/slice';
import type { AvailabilityProviderDetails } from '../availability/slice';

export enum UtilizationLegendEnum {
    Unknown,
    NotAcceptingNewPatients,
    OverCapacityNotAcceptingNewPatients,
    OverCapacityNotAcceptingNewPatientsAtSite,
    OverCapacityAcceptingNewPatients,
    AvailableCapacityNotAcceptingNewPatients,
    AvailableCapacityNotAcceptingNewPatientsAtSite,
    AvailableCapacityAcceptingNewPatients,
    CloseCapacity,
    AvailableCapacity,
}

export enum UtilizationLegendCategoryEnum {
    Available,
    Unavailable,
    CloseCapacity,
}

export const utilizationMessagesConstants = {
    Unknown: 'Target caseload not set for this user.',
    OverCapacity: 'of target caseload.',
};
export const utilizationLegendConstants: Map<string, string> = new Map([
    ['CloseCapacity', 'Current caseload is approaching target.'],
    ['OverCapacityNotAcceptingNewPatients', 'Caseload target has been reached, not currently accepting new patients.'],
    [
        'OverCapacityNotAcceptingNewPatientsAtSite',
        'Caseload target has been reached, not currently accepting new patients at this site.',
    ],
    ['OverCapacityAcceptingNewPatients', 'Caseload target has been reached.'],
    ['AvailableCapacityNotAcceptingNewPatients', 'Not currently accepting new patients.'],
    ['AvailableCapacityNotAcceptingNewPatientsAtSite', 'Not currently accepting new patients at this site.'],
    ['AvailableCapacity', 'Good option for new patients, based on current caseload.'],
    ['AvailableCapacityAcceptingNewPatients', 'Current caseload can accommodate new patients.'],
    ['Unknown', 'Good option for new patients, based on current caseload.'],
]);

export type UtilizationForUser = {
    category: UtilizationLegendEnum;
    messageCategory: UtilizationLegendCategoryEnum;
    rate: number;
};

const calculateUtilization = (
    user: User,
    episode: Episode | null,
    includeToBeAssignedEpisode?: boolean,
): UtilizationForUser => {
    if (user.targetCaseLoad === undefined) {
        return {
            category: UtilizationLegendEnum.Unknown,
            rate: 100,
            messageCategory: UtilizationLegendCategoryEnum.Available,
        };
    }
    const additionalEpisodeCount = user.id === episode?.ownerId ? 0 : 1;
    const utilRate = calcAllocationRate(
        (user.numOfActiveEpisodes ?? 0) + (includeToBeAssignedEpisode ? additionalEpisodeCount : 0),
        user.targetCaseLoad,
    );
    if (utilRate >= 100 && user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.OverCapacityAcceptingNewPatients,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate >= 100 && user.notAcceptingType === 'NOT_ACCEPTING_GENERAL' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.OverCapacityNotAcceptingNewPatients,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate >= 100 && user.notAcceptingType === 'NOT_ACCEPTING_SITE' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.OverCapacityNotAcceptingNewPatientsAtSite,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate <= 80 && user.notAcceptingType === 'NOT_ACCEPTING_GENERAL' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.AvailableCapacityNotAcceptingNewPatients,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate <= 80 && user.notAcceptingType === 'NOT_ACCEPTING_SITE' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.AvailableCapacityNotAcceptingNewPatientsAtSite,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate <= 80 && user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.AvailableCapacityAcceptingNewPatients,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Available,
        };
    }
    if (utilRate > 80 && user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.CloseCapacity,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.CloseCapacity,
        };
    }
    if (utilRate < 100 && user.notAcceptingType === 'NOT_ACCEPTING_SITE' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.AvailableCapacityNotAcceptingNewPatientsAtSite,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    if (utilRate < 100 && user.notAcceptingType === 'NOT_ACCEPTING_GENERAL' && !user.acceptingNewPatients) {
        return {
            category: UtilizationLegendEnum.AvailableCapacityNotAcceptingNewPatients,
            rate: Math.round(utilRate),
            messageCategory: UtilizationLegendCategoryEnum.Unavailable,
        };
    }
    return {
        category: UtilizationLegendEnum.AvailableCapacity,
        rate: Math.round(utilRate),
        messageCategory: UtilizationLegendCategoryEnum.Available,
    };
};

export const calcAllocationRate = (numOfEpisodes?: number, targetCaseLoad?: number): number => {
    const allocation = ((numOfEpisodes || 0) / (targetCaseLoad || 0)) * 100;
    return isNaN(allocation) && !isFinite(allocation) ? 0 : Math.round(allocation);
};

export const isEpisodeOwner = (
    providerDetails: AvailabilityProviderDetails | null,
    episode: Episode | null,
): boolean => {
    if (!providerDetails) return false;
    return providerDetails.provider.id === episode?.ownerId;
};

export const isNotAcceptingNewPatients = (providerDetails: AvailabilityProviderDetails): boolean => {
    return !providerDetails.acceptingNewPatients;
};

export const isNotAvailableForEpisodeEngagementType = (
    providerDetails: AvailabilityProviderDetails,
    episode: Episode,
): boolean => {
    return !providerDetails.engagementTypes?.includes(episode?.engagementType);
};

export const getCircleColor = (utilCategory: UtilizationLegendCategoryEnum): string => {
    if (utilCategory === UtilizationLegendCategoryEnum.Unavailable) {
        return AvailabilityCircleColor.red;
    }

    if (utilCategory === UtilizationLegendCategoryEnum.CloseCapacity) {
        return AvailabilityCircleColor.orange;
    }

    return AvailabilityCircleColor.green;
};

export default calculateUtilization;
