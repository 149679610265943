import jsforce from 'jsforce';
import { SFConfig } from '../config';

export const AuthService = {
    authorizewithSF: (currentUrl: string, sfConfig: SFConfig): string => {
        const sfOauth2 = new jsforce.OAuth2({
            loginUrl: sfConfig.loginUrl,
            clientId: sfConfig.clientId,
            redirectUri: sfConfig.redirectUri,
        });
        return sfOauth2.getAuthorizationUrl({ scope: 'api', state: currentUrl });
    },
};
