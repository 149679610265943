export const TOKEN_EXPIRED_MESSAGE = 'Token has expired';
export const DEFAULT_GRAPHQL_URL = 'http://localhost:3001/graphql';

export enum ApiException {
    Unauthorized = 'UnauthorizedException',
}

export enum AppSyncAuthorizationHeaders {
    Authorization = 'Authorization',
    ApiKey = 'x-api-key',
}
