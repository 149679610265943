import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './store';
import './global';
import { addInterceptors, initializeAxiosConfigDefaults } from './service-client/http-interceptors';
import { isDevelopment } from './util';

addInterceptors(store.dispatch);
initializeAxiosConfigDefaults();

if (isDevelopment() && (window as any).config.useMockApi) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { worker } = require('./mocks/browser');
    worker.start({ waitUntilReady: true });
}

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
