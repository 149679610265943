import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';

export type Practice = {
    name: string;
    id: string;
    acceptingNewPatients?: boolean;
};

export const initialState = {
    data: [] as Practice[],
    error: null,
    status: ApiCallStatus.idle,
};

const practicesSlice = createSlice({
    name: 'practices',
    initialState,
    reducers: {
        getPractices(state, action) {
            state.status = ApiCallStatus.loading;
            return state;
        },
        clearPracticesFilter(state) {
            state.data = [];
        },
        setPracticesSuccess(state, action) {
            state.status = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        setPracticesFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { getPractices, setPracticesSuccess, setPracticesFailure, clearPracticesFilter } = practicesSlice.actions;

export const { reducer } = practicesSlice;
