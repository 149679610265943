export const durationSelectItems = [
    { name: '15m', key: '15', durationMin: 15 },
    { name: '30m', key: '30', durationMin: 30 },
    { name: '45m', key: '45', durationMin: 45 },
    { name: '60m', key: '60', durationMin: 60 },
];

export const eventTypeItems = [
    { label: 'Phone Call', value: 'Phone Call' },
    { label: 'Video Meeting', value: 'Video Meeting' },
    { label: 'In-Person Session', value: 'In-Person Session' },
];

export const noCommunicationErrorMessages = {
    noNotificationWillBeSent: `No appointment notifications will be sent. Please refer to the Patient's Notification Preferences for more details.`,
};

export const ageOfmajority = 18;
