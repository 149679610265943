import { put, select, takeEvery } from 'redux-saga/effects';
import { showSessionEndedDialog, unAuthenticate, userUnauthorized, usesSessionEndedDialog } from './slice';

export function* showSessionEndedDialogApi() {
    try {
        const canShowModal: boolean = yield select((state) => state.auth.usesSessionEndedDialogModal);
        if (canShowModal) {
            yield put(showSessionEndedDialog());
        } else {
            const token: string = yield select((state) => state.auth.token);
            if (token) {
                yield put(unAuthenticate());
            }
        }
    } finally {
        yield put(usesSessionEndedDialog(false));
    }
}

export default function* watchUnauthorizedRequest(): Generator {
    yield takeEvery(userUnauthorized, showSessionEndedDialogApi);
}
