import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';

export type Language = {
    label: string;
    value: string;
};

export const initialState = {
    data: [] as any,
    error: null,
    status: ApiCallStatus.idle,
};

const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        getLanguages(state) {
            state.status = ApiCallStatus.loading;
            return state;
        },
        clearLanguagesFilter(state) {
            state.data = [];
        },
        setLanguageSuccess(state, action) {
            state.status = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        setLanguagesFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { getLanguages, setLanguageSuccess, setLanguagesFailure, clearLanguagesFilter } = languagesSlice.actions;

export const { reducer } = languagesSlice;
