import { FC, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Practice } from './slice';
import './styles.scss';

export type PracticeMultiSelectorProps = {
    onSelectedPractice: (practice: Practice[]) => void;
    selectedPracticeParam: Practice[] | null;
    filteredPractices: Practice[];
    isDisable: boolean;
};

export const PracticeMultiSelector: FC<PracticeMultiSelectorProps> = ({
    onSelectedPractice,
    selectedPracticeParam,
    filteredPractices,
    isDisable,
}) => {
    const [selectedPractices, setSelectedPractices] = useState(selectedPracticeParam);

    const onChangePractice = (newValue: any) => {
        setSelectedPractices(newValue);
        onSelectedPractice(newValue);
    };
    return (
        <MultiSelect
            optionLabel="name"
            value={selectedPractices}
            options={filteredPractices}
            display="chip"
            disabled={isDisable}
            placeholder="Practices..."
            className="practice-multi-selector"
            filter
            onChange={(e: any) => onChangePractice(e.value)}
        />
    );
};
