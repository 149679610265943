import { ApiCallStatus } from '../../../services/api-call-status';
import { RootState } from '../../../store';

export const selectIsConcertSettingsLoading = (state: RootState): boolean =>
    state.concertSettings.getStatus === ApiCallStatus.loading;
export const selectIsAcceptingNewPatientsSaving = (state: RootState): boolean =>
    state.concertSettings.saveAcceptingNewPatientsStatus === ApiCallStatus.loading;
export const selectConcertSettings = (state: RootState) => state.concertSettings.data;
export const selectUserConcertSettings = (state: RootState) => state.concertSettings.data.userConcertSetting;
export const selectUserPractices = (state: RootState) => state.concertSettings.data.userPractices;
export const selectUserPracticeSelections = (state: RootState) => state.concertSettings.selections;
export const selectIsUserPracticesSucceeded = (state: RootState) =>
    state.concertSettings.userPracticeSaveStatus === ApiCallStatus.succeeded;
export const selectIsUserPracticesUpdating = (state: RootState) =>
    state.concertSettings.userPracticeUpdateStatus === ApiCallStatus.loading;
export const selectIsUserPracticesUpdateSucceeded = (state: RootState) =>
    state.concertSettings.userPracticeUpdateStatus === ApiCallStatus.succeeded;
export const selectIsAcceptingNewPatientsSucceeded = (state: RootState): boolean =>
    state.concertSettings.saveAcceptingNewPatientsStatus === ApiCallStatus.succeeded;
export const selectIsEngagementTypesSaving = (state: RootState): boolean =>
    state.concertSettings.saveEngagementTypesStatus === ApiCallStatus.loading;
export const selectIsEngagementTypesSucceeded = (state: RootState): boolean =>
    state.concertSettings.saveEngagementTypesStatus === ApiCallStatus.succeeded;
