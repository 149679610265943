import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FC, useEffect, useState } from 'react';
import { AvailabilityProvider, AvailabilitySettingsSetterDialog, Loader } from 'concert-ui-library';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { AvailabilitySettingsDataTableRow } from '../onsite-availability';
import {
    AvailabilitySettingByDate,
    AvailabilitySettingByDay,
    AvailabilitySettingTimeslot,
    AvailabilitySettings,
    useLazyGetGeneralUserAvailabilitySettingsByDateQuery,
} from '../../../services/graphql/generated';
import {
    AvailabilitySettingsUpdateRequest,
    SettingsRow,
    transformAvailabilitySettings,
} from '../availability-functions';
import { AvailabilitySettingCategoryMap, AvailabilityType, AvailabilityCategory } from '../slice';
import { SchedulingDateFunctions } from '../../../services/scheduling-date-fns';
import { AVAILABILITY_CUSTOM_VALIDATION_ERROR_MESSAGE } from '../constants';

export interface IExtendedAvailability {
    userId: string;
    availabilitySettings: AvailabilitySettings | null;
    dateKeyFormat: string;
    dateRenderFormat: string;
    isUpdating: boolean;
    isUpdated: boolean;
    isReadonly: boolean;
    onSaving: (settingsToSave: AvailabilitySettingsUpdateRequest) => void;
}

export const ExtendedAvailability: FC<IExtendedAvailability> = ({
    userId,
    availabilitySettings,
    dateKeyFormat,
    dateRenderFormat,
    isReadonly,
    isUpdating,
    isUpdated,
    onSaving,
}) => {
    const [getAvailabilitySettings, { data: settings, isFetching: isAvailabilityLoading }] =
        useLazyGetGeneralUserAvailabilitySettingsByDateQuery();

    const [selectedAvailSettingsToEdit, setSelectedAvailSettingsToEdit] =
        useState<null | AvailabilitySettingCategoryMap>(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [isAvailabilityUpdateCompleted, setIsAvailabilityUpdateCompleted] = useState(false);
    const [isDeletingRow, setIsDeletingRow] = useState(false);
    const [siteAvailSettingsByDate, setSiteAvailSettingsByDate] = useState(
        transformAvailabilitySettings(availabilitySettings?.general?.byDate ?? null, dateKeyFormat, dateRenderFormat),
    );

    useEffect(() => {
        const byDateSettings = settings
            ? settings.getAvailabilitySettings?.general?.byDate ?? null
            : availabilitySettings?.general?.byDate ?? null;
        setSiteAvailSettingsByDate(transformAvailabilitySettings(byDateSettings, dateKeyFormat, dateRenderFormat));
    }, [availabilitySettings, settings]);

    const editAvailabilityRow = (dateParam: string) => {
        const dateSettings = availabilitySettings
            ? siteAvailSettingsByDate?.find(
                  (settingGroup: AvailabilitySettingsDataTableRow) => settingGroup?.dateOfAvailability === dateParam,
              )
            : null;
        setSelectedAvailSettingsToEdit(
            dateSettings
                ? {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: dateParam, timeslots: dateSettings.times },
                  }
                : {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: dateParam, timeslots: [] },
                  },
        );
        setDisplayDialog(true);
    };
    const timesBodyTemplate = (rowData: AvailabilitySettingsDataTableRow) => {
        return rowData.times.map((StartToEndTime: AvailabilitySettingTimeslot) => {
            return (
                <div key={StartToEndTime.id}>
                    {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDuration(StartToEndTime.startTime)} -{' '}
                    {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDuration(StartToEndTime.endTime)}
                </div>
            );
        });
    };

    const weekDayBodyTemplate = (rowData: AvailabilitySettingsDataTableRow) => {
        return (
            <div>
                <b>{SchedulingDateFunctions.getWeekDayString(rowData.dateOfAvailability, dateKeyFormat) || ''}</b>
            </div>
        );
    };
    const editBodyTemplate = (rowData: AvailabilitySettingsDataTableRow) => {
        return (
            <div className="edit-body-template">
                <span className="button-container">
                    <Button
                        aria-label="edit extended availability"
                        icon="pi pi-pencil"
                        style={{ width: '40px' }}
                        onClick={() => editAvailabilityRow(rowData.dateOfAvailability)}
                    />
                </span>
                <span className="button-container">
                    <Button
                        aria-label="delete extended availability"
                        icon="pi pi-trash"
                        style={{ width: '40px' }}
                        onClick={() => {
                            onSaving({
                                settingsToBeAdded: [],
                                settingsToBeDeleted: [...rowData.times],
                                settingsToBeModified: [],
                            });
                            setIsDeletingRow(true);
                        }}
                    />
                </span>
            </div>
        );
    };
    const onSelectedDateToEdit = (dateToEdit: Date | null) => {
        if (!dateToEdit) {
            setSelectedAvailSettingsToEdit({
                availabilityType: AvailabilityType.ByDate,
                value: { date: null, timeslots: [] },
            });
            return;
        }

        const luxonDateToEdit = DateTime.fromJSDate(dateToEdit);
        const dateKey = luxonDateToEdit.toFormat(dateKeyFormat);
        const siteAvailSettingsApi = siteAvailSettingsByDate?.find(
            (p: AvailabilitySettingsDataTableRow) => p?.dateOfAvailability === dateKey,
        );
        setSelectedAvailSettingsToEdit(
            siteAvailSettingsApi
                ? {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: luxonDateToEdit.toISODate(), timeslots: siteAvailSettingsApi.times },
                  }
                : {
                      availabilityType: AvailabilityType.ByDate,
                      value: { date: luxonDateToEdit.toISODate(), timeslots: [] },
                  },
        );
    };
    const onHide = (dataChanged: boolean) => {
        setDisplayDialog(false);
        dataChanged && setIsAvailabilityUpdateCompleted(false);
    };
    const onNewClick = () => {
        setDisplayDialog(true);
        onSelectedDateToEdit(null);
    };
    const addExtendedAvailabilityButton = () => {
        return (
            <Button
                label="Extended Availability"
                icon="pi pi-plus"
                className="p-button p-component p-button-secondary"
                disabled={isReadonly}
                onClick={onNewClick}
            />
        );
    };

    const extractSlotsNumber = (
        extendedRows: SettingsRow[] | AvailabilitySettingTimeslot[],
        timeslotFormat: string,
    ) => {
        let totalSlots = 0;
        extendedRows.forEach((row: SettingsRow) => {
            let startTime: DateTime = DateTime.fromFormat(row.startTime || '', timeslotFormat);
            const endTime: DateTime = DateTime.fromFormat(row.endTime || '', timeslotFormat);
            while (startTime < endTime) {
                startTime = startTime.plus({ minute: 30 });
                totalSlots++;
            }
        });
        return totalSlots;
    };

    const validateExtendedRows = (extendedRows: SettingsRow[]): { isValid: boolean; message: string } => {
        const validationState = {
            isValid: true,
            message: '',
        };
        if (!extendedRows.length) {
            return validationState;
        }
        const totalSlots = extractSlotsNumber(extendedRows, 'h:m a');
        const availability = availabilitySettings?.general?.byDay?.find(
            (p: AvailabilitySettingByDay) =>
                p.day ===
                SchedulingDateFunctions.getWeekDayString(extendedRows[0].dateOfAvailability || '', dateKeyFormat),
        );
        const generalSlots = extractSlotsNumber(availability?.timeslots || [], 'hh:mm:ss');
        if (generalSlots > totalSlots) {
            validationState.isValid = false;
            validationState.message = AVAILABILITY_CUSTOM_VALIDATION_ERROR_MESSAGE;
        }
        return validationState;
    };

    useEffect(() => {
        if (!isUpdated) return;

        getAvailabilitySettings({
            request: { userIds: [userId] as string[] },
        });

        setIsAvailabilityUpdateCompleted(isUpdated);
        onHide(true);
        setIsDeletingRow(false);
    }, [isUpdated]);
    return (
        <div>
            {isAvailabilityLoading || isDeletingRow ? (
                <Loader />
            ) : (
                <>
                    <Toolbar left={addExtendedAvailabilityButton} />
                    <DataTable
                        className="extended-availability-table"
                        value={siteAvailSettingsByDate ?? []}
                        dataKey="id"
                    >
                        <Column field="dateOfAvailabilityShort" headerStyle={{ display: 'none' }} />
                        <Column field="weekday" body={weekDayBodyTemplate} headerStyle={{ display: 'none' }} />
                        <Column field="times" body={timesBodyTemplate} headerStyle={{ display: 'none' }} />
                        <Column
                            hidden={isReadonly}
                            field="editDeleteIcon"
                            body={editBodyTemplate}
                            headerStyle={{ display: 'none' }}
                        />
                    </DataTable>
                    {displayDialog && (
                        <AvailabilityProvider>
                            <AvailabilitySettingsSetterDialog
                                onClose={onHide}
                                availabilitySettings={selectedAvailSettingsToEdit?.value.timeslots || []}
                                date={
                                    selectedAvailSettingsToEdit?.availabilityType === AvailabilityType.ByDate
                                        ? (selectedAvailSettingsToEdit?.value as AvailabilitySettingByDate).date
                                        : undefined
                                }
                                onDateSelected={onSelectedDateToEdit}
                                availabilityType={selectedAvailSettingsToEdit?.availabilityType || ''}
                                category={AvailabilityCategory.General}
                                customValidation={validateExtendedRows}
                                userId={userId}
                                onSaving={(settingsToSave: AvailabilitySettingsUpdateRequest) =>
                                    onSaving({
                                        settingsToBeAdded: [...settingsToSave.settingsToBeAdded],
                                        settingsToBeDeleted: [...settingsToSave.settingsToBeDeleted],
                                        settingsToBeModified: [...settingsToSave.settingsToBeModified],
                                    })
                                }
                                defaultStartTime="8:00 AM"
                                defaultEndTime="4:00 PM"
                                defaultStepMinute={30}
                                isLoading={isUpdating}
                                isSaved={isAvailabilityUpdateCompleted}
                            />
                        </AvailabilityProvider>
                    )}
                </>
            )}
        </div>
    );
};
