import { Card } from 'primereact/card';
import { FunctionComponent } from 'react';
import { SchedulingDateFunctions } from '../../services/scheduling-date-fns';
import { AvailabilityCategory, dayOfWeekDisplay } from './slice';
import { calculateWeeklyAvailableHours, showWeeklyClinicalHours } from './general-availability-functions';
import './settings.scss';
import { AvailabilitySettingByDay, AvailabilitySettingTimeslot } from '../../services/graphql/generated';
import { isSubmitKey } from '../../util';

export type WeekDayViewProps = {
    settings: AvailabilitySettingByDay[] | null | undefined;
    availCategory: string;
    isEditDisabled: boolean;
    onClickEdit: (entry: AvailabilitySettingByDay) => void;
    userWeeklyClinicalHours?: number | null;
};

export const WeekDayView: FunctionComponent<WeekDayViewProps> = ({
    settings,
    availCategory,
    isEditDisabled,
    onClickEdit,
    userWeeklyClinicalHours,
}) => {
    const dayOfWeekEntries = [...dayOfWeekDisplay.entries()];
    const sumOfWeeklyAvailableHours = calculateWeeklyAvailableHours(settings);
    const weeklyClinicalHours = showWeeklyClinicalHours(userWeeklyClinicalHours);

    const editAvailability = (entry: [string, string], availSettingsForThisDay: AvailabilitySettingByDay) => {
        if (isEditDisabled) {
            return false;
        }
        onClickEdit({
            day: entry[0],
            timeslots: availSettingsForThisDay?.timeslots,
        });
        return true;
    };

    return (
        <Card className="settings-screen">
            <div className="availability-week" role="table">
                {dayOfWeekEntries.map((entry) => {
                    return (
                        <div className="header" key={entry[1]} role="rowheader">
                            {entry[1].toUpperCase()}
                        </div>
                    );
                })}
                {dayOfWeekEntries.map((entry) => {
                    const availSettingsForThisDay = settings
                        ? (settings.find(
                              (p: AvailabilitySettingByDay) => p.day === entry[0],
                          ) as AvailabilitySettingByDay)
                        : ({} as AvailabilitySettingByDay);
                    return (
                        <div
                            className={isEditDisabled ? 'content disabled' : 'content p-button-secondary'}
                            key={entry[0]}
                            onClick={() => editAvailability(entry, availSettingsForThisDay)}
                            onKeyUp={(ev: React.KeyboardEvent<HTMLDivElement>) => {
                                if (isSubmitKey(ev)) {
                                    editAvailability(entry, availSettingsForThisDay);
                                }
                            }}
                            tabIndex={0}
                            role="button"
                        >
                            {availSettingsForThisDay &&
                                (availSettingsForThisDay.timeslots as AvailabilitySettingTimeslot[])?.map(
                                    (setting: AvailabilitySettingTimeslot) => {
                                        return (
                                            <div key={setting.id}>
                                                {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDuration(
                                                    setting.startTime,
                                                )}
                                                <span> - </span>
                                                {SchedulingDateFunctions.getTimeDisplayStringHoursMinutesFromDuration(
                                                    setting.endTime,
                                                )}
                                                <div>
                                                    {availCategory === AvailabilityCategory.Site &&
                                                        ` At ${setting.practice?.name}`}
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                        </div>
                    );
                })}
            </div>
            {availCategory === AvailabilityCategory.General && (
                <div className="general-availability-footer">
                    Weekly Availability: {sumOfWeeklyAvailableHours} hours
                </div>
            )}
            {availCategory === AvailabilityCategory.General && (
                <div className="general-availability-footer">Weekly Clinical Hours: {weeklyClinicalHours}</div>
            )}
        </Card>
    );
};
