import { FC, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import { selectLanguages } from './selector';
import './language-selector.scss';

export type LanguageSelectorProps = {
    onSelectedLanguage: (language: string) => void;
    selectedLanguageParam: string | null;
};

export const LanguageSelector: FC<LanguageSelectorProps> = ({ onSelectedLanguage, selectedLanguageParam }) => {
    const languages = useSelector(selectLanguages);
    const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageParam);

    const onChangeLanguage = (newValue: any) => {
        setSelectedLanguage(newValue);
        onSelectedLanguage(newValue);
    };

    useEffect(() => {
        setSelectedLanguage(selectedLanguageParam);
    }, [selectedLanguageParam]);

    return (
        <Dropdown
            optionLabel="label"
            value={selectedLanguage}
            options={languages}
            optionValue="value"
            showClear
            className="language-selector"
            placeholder="Languages..."
            onChange={({ value }: any) => onChangeLanguage(value)}
        />
    );
};
