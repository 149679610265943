import { DateTime as luxonDateTime, Duration, Interval } from 'luxon';

const getLuxonDate = (dateParam: Date, timezone: string): luxonDateTime => {
    const luxonDate = luxonDateTime.fromJSDate(dateParam);
    return luxonDateTime.fromObject({
        year: luxonDate.year,
        month: luxonDate.month,
        day: luxonDate.day,
        hour: 0,
        minute: 0,
        second: 0,
        zone: timezone,
    });
};

const getDateOnlyDisplayString = (dateToDisplay: luxonDateTime): string => {
    return dateToDisplay.toLocaleString(luxonDateTime.DATE_MED_WITH_WEEKDAY);
};

const getTimezoneDisplayStringFromDate = (date: luxonDateTime): string => {
    return date.offsetNameLong;
};

const getTimeDisplayStringHourMinutes = (date: luxonDateTime, time: string): string => {
    const startDuration = Duration.fromISOTime(time);
    const timeSlotDateWithTime = date.set({ hour: startDuration.hours, minute: startDuration.minutes });
    return timeSlotDateWithTime.toLocaleString(luxonDateTime.TIME_SIMPLE);
};

const getTimeDisplayStringHoursMinutesFromDate = (date: luxonDateTime): string => {
    return date.toLocaleString(luxonDateTime.TIME_SIMPLE);
};

const getTimeDisplayStringHoursMinutesFromDuration = (durationString: string): string => {
    const duration = Duration.fromISOTime(durationString);
    return luxonDateTime
        .fromObject({ hour: duration.hours, minute: duration.minutes, second: 0, millisecond: 0 })
        .toLocaleString(luxonDateTime.TIME_SIMPLE);
};

const getTimeStringFromTimeDisplayString = (displayString: string): string => {
    return luxonDateTime.fromFormat(displayString, 'h:m a').toFormat('HH:mm');
};

const getUsersTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getTimeSlotsForDay = () => {
    return Interval.after(
        luxonDateTime.fromObject({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        Duration.fromISOTime('24:00:00'),
    )
        .splitBy(Duration.fromISOTime('00:30:00'))
        .map((interval) => getTimeDisplayStringHoursMinutesFromDate(interval.start));
};

const getIntervalFromTimes = (startTime: string, endTime: string): Interval => {
    const startDate = luxonDateTime.fromFormat(startTime, 'h:m a');
    const endDate = luxonDateTime.fromFormat(endTime, 'h:m a');
    return Interval.fromDateTimes(startDate, endDate);
};

const getIntervalFromTimesMilitaryFormat = (startTime: string, endTime: string): Interval => {
    const startDate = luxonDateTime.fromFormat(startTime, 'hh:mm:ss');
    const endDate = luxonDateTime.fromFormat(endTime, 'hh:mm:ss');
    return Interval.fromDateTimes(startDate, endDate);
};

const getLuxonDateFromTimeString = (time: string): luxonDateTime => {
    return luxonDateTime.fromFormat(time, 'h:m a');
};

const getCurrentTimeDisplayStringInTimezone = (timezone: string) => {
    return luxonDateTime.now().setZone(timezone).toLocaleString(luxonDateTime.TIME_SIMPLE);
};

const getLuxonDateTimeFromDateString = (dateString: string, format?: string): luxonDateTime => {
    return luxonDateTime.fromFormat(dateString, format || 'MM/dd/yyyy');
};

const getWeekDayString = (dateString: string, format: string): string => {
    return luxonDateTime.fromFormat(dateString, format).weekdayLong;
};

const getCurrentShortTimezone = (timezone: string) => {
    return luxonDateTime.now().setZone(timezone).toFormat('ZZZZ');
};

export const SchedulingDateFunctions = {
    getLuxonDate,
    getLuxonDateTimeFromDateString,
    getUsersTimezone,
    getDateOnlyDisplayString,
    getTimeDisplayStringHourMinutes,
    getTimezoneDisplayStringFromDate,
    getTimeDisplayStringHoursMinutesFromDate,
    getTimeDisplayStringHoursMinutesFromDuration,
    getTimeSlotsForDay,
    getTimeStringFromTimeDisplayString,
    getIntervalFromTimes,
    getIntervalFromTimesMilitaryFormat,
    getLuxonDateFromTimeString,
    getCurrentTimeDisplayStringInTimezone,
    getWeekDayString,
    getCurrentShortTimezone,
};
