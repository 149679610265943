import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    appError: null,
};

const errorSlice = createSlice({
    name: 'appError',
    initialState,
    reducers: {
        addError(state, action) {
            console.log(action.payload);
            state.appError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addDefaultCase((state, action) => {
            if (
                !!action.payload &&
                action.payload.error !== null &&
                action.payload.error !== '' &&
                action.payload.error !== undefined
            ) {
                state.appError = action.payload.error;
            }
        });
    },
});

export const { addError } = errorSlice.actions;

export const { reducer } = errorSlice;
