import { rest } from 'msw';

export const restHandlers = [
    rest.get('https://localhost:44392/practices/00119000014eCtlAAE', (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({ id: '00119000014eCtlAAE', name: 'Test NY FQHC' }));
    }),
    rest.get('https://localhost:44392/practices', (req, res, ctx) => {
        return res(ctx.status(200), ctx.json([{ id: '00119000014eCtlAAE', name: 'Test NY FQHC' }]));
    }),
    rest.get('https://localhost:44392/engagement-types', (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(['Standard Collaborative Care']));
    }),
    rest.post('https://localhost:44392/availability-settings/search', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                general: {
                    byDate: [],
                    byDay: [
                        {
                            day: 'Monday',
                            timeslots: [
                                {
                                    startTime: '09:00:00',
                                    endTime: '17:00:00',
                                    dayOfWeek: 'Monday',
                                    userId: '0058Y00000At0ONQAZ',
                                    patientId: 'patientTestId',
                                    availabilityType: 'ByDay',
                                    id: 'a1M03000000d8TeEAI',
                                    category: 'General',
                                    dateOfAvailability: null,
                                    practice: null,
                                    modifiedByEC: false,
                                },
                            ],
                        },
                        {
                            day: 'Tuesday',
                            timeslots: [
                                {
                                    startTime: '09:00:00',
                                    endTime: '17:00:00',
                                    dayOfWeek: 'Monday',
                                    userId: '0058Y00000At0ONQAZ',
                                    patientId: 'patientTestId',
                                    availabilityType: 'ByDay',
                                    id: 'a1M03000000d8TeEAI',
                                    category: 'General',
                                    dateOfAvailability: null,
                                    practice: null,
                                    modifiedByEC: false,
                                },
                            ],
                        },
                        {
                            day: 'Wednesday',
                            timeslots: [],
                        },
                        {
                            day: 'Thursday',
                            timeslots: [],
                        },
                        {
                            day: 'Friday',
                            timeslots: [],
                        },
                        {
                            day: 'Saturday',
                            timeslots: [],
                        },
                        {
                            day: 'Sunday',
                            timeslots: [],
                        },
                    ],
                },
            }),
        );
    }),
    rest.get('https://localhost:44392/episodes/a0019000006wFmvAAE', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 'a0019000006wFmvAAE',
                ownerId: '0052M00000A0uWeQAJ',
                name: 'Test Irving 2022-05-16',
                practiceName: 'Test NY FQHC',
                providerName: 'Dr Lahey',
                timezone: 'America/New_York',
                patientEmail: 'malia@concerthealth.io',
                patientAge: 7,
                hasOptedOutOfEmail: false,
                appointmentNotificationsEmailOptOut: false,
                practiceState: 'NY',
                patientId: 'testId',
                practiceId: '00119000014eCtlAAE',
                ownerName: 'Emily Galea',
                patientLanguage: 'English',
                engagementType: 'Standard Collaborative Care',
            }),
        );
    }),
    rest.get('https://localhost:44392/episodes', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: 'a0019000006wFmvAAE',
                    ownerId: '0052M00000A0uWeQAJ',
                    name: 'Test Irving 2022-05-16',
                    practiceName: 'Test NY FQHC',
                    providerName: 'Dr Lahey',
                    timezone: 'America/New_York',
                    patientEmail: 'malia@concerthealth.io',
                    patientAge: 7,
                    hasOptedOutOfEmail: false,
                    appointmentNotificationsEmailOptOut: false,
                    practiceState: 'NY',
                    practiceId: '00119000014eCtlAAE',
                    ownerName: 'Emily Galea',
                    patientLanguage: 'English',
                    engagementType: 'Standard Collaborative Care',
                },
            ]),
        );
    }),
    rest.get('https://localhost:44392/languages', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    label: 'English',
                    value: 'English',
                },
                {
                    label: 'Spanish',
                    value: 'Spanish',
                },
                {
                    label: 'ASL',
                    value: 'ASL',
                },
                {
                    label: 'Albanian',
                    value: 'Albanian',
                },
                {
                    label: 'Arabic',
                    value: 'Arabic',
                },
                {
                    label: 'Armenian',
                    value: 'Armenian',
                },
                {
                    label: 'Bengali',
                    value: 'Bengali',
                },
                {
                    label: 'Burmese',
                    value: 'Burmese',
                },
                {
                    label: 'Cantonese',
                    value: 'Cantonese',
                },
                {
                    label: 'Chinese',
                    value: 'Chinese',
                },
                {
                    label: 'Creole',
                    value: 'Creole',
                },
                {
                    label: 'Farsi',
                    value: 'Farsi',
                },
                {
                    label: 'French',
                    value: 'French',
                },
                {
                    label: 'German',
                    value: 'German',
                },
                {
                    label: 'Greek',
                    value: 'Greek',
                },
                {
                    label: 'Haitian',
                    value: 'Haitian',
                },
                {
                    label: 'Hebrew',
                    value: 'Hebrew',
                },
                {
                    label: 'Hindi',
                    value: 'Hindi',
                },
                {
                    label: 'Hmong',
                    value: 'Hmong',
                },
                {
                    label: 'Italian',
                    value: 'Italian',
                },
                {
                    label: 'Japanese',
                    value: 'Japanese',
                },
                {
                    label: 'Karen',
                    value: 'Karen',
                },
                {
                    label: 'Khmer',
                    value: 'Khmer',
                },
                {
                    label: 'Korean',
                    value: 'Korean',
                },
                {
                    label: 'Lahnda',
                    value: 'Lahnda',
                },
                {
                    label: 'Laotian',
                    value: 'Laotian',
                },
                {
                    label: 'Lithuanian',
                    value: 'Lithuanian',
                },
                {
                    label: 'Malay',
                    value: 'Malay',
                },
                {
                    label: 'Mandarin',
                    value: 'Mandarin',
                },
                {
                    label: 'Nepali',
                    value: 'Nepali',
                },
                {
                    label: 'Polish',
                    value: 'Polish',
                },
                {
                    label: 'Portuguese',
                    value: 'Portuguese',
                },
                {
                    label: 'Punjabi',
                    value: 'Punjabi',
                },
                {
                    label: 'Romanian',
                    value: 'Romanian',
                },
                {
                    label: 'Russian',
                    value: 'Russian',
                },
                {
                    label: 'Somali',
                    value: 'Somali',
                },
                {
                    label: 'Tagalog',
                    value: 'Tagalog',
                },
                {
                    label: 'Thai',
                    value: 'Thai',
                },
                {
                    label: 'Turkish',
                    value: 'Turkish',
                },
                {
                    label: 'Vietnamese',
                    value: 'Vietnamese',
                },
                {
                    label: 'Other / Unknown',
                    value: 'Other_Unknown',
                },
            ]),
        );
    }),
    rest.get('https://localhost:44392/users/*', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: '005190000042dRPAAY',
                name: 'Renan test Borges',
                nameWithSuffix: 'Renan test Borges',
                timezone: 'America/Tijuana',
                roleName: 'Software Engineer',
                languages: 'English',
                region: 'NY',
                doxyLink: 'test.ec.concerthealth.io',
                photoUrl: 'https://concerthealth--develop.sandbox.file.force.com/profilephoto/005/T',
                phone: '12222222222',
                targetCaseLoad: null,
                numOfActiveEpisodes: 0,
                numOfActiveEpisodesForPractice: 0,
                managerId: null,
                profileName: 'System Administrator',
                isSysAdmin: true,
                hasConcertSettingsManagerAccess: false,
            }),
        );
    }),
    rest.post('https://localhost:44392/calendar-event', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                status: 0,
                data: {
                    confirmationEmailStatus: 'SUCCESS',
                    confirmationEmailErrorMessage: '',
                },
            }),
        );
    }),
];
